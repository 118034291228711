import React, { useEffect } from 'react'
import {Link} from 'react-router-dom'
import Button from './Components/Button'
import './App.css'
import esriConfig from "@arcgis/core/config.js";
esriConfig.assetsPath = "./assets"; 

const App = () => {
    useEffect(() => {
        document.title = "WE ACT Forms"
    }, [])

  return (
    //   <div className='body'>
        <div className='buttonsdiv p-2'>
            {/* <div>
                <Link to='/test-applications'><Button text="Test Student Application Form"/></Link>
            </div> */}
            
            <div>
                <Link to='/we-act-application'><Button text="WE ACT Registration Form"/></Link>
            </div>
            
            <div>
                <Link to='/training-pre-survey'><Button text="Pre Training Form"/></Link>
            </div>
            
            <div>
                <Link to='/training-post-survey'><Button text="Post Training Form"/></Link>
            </div>

            <div>
                <Link to='/uploads'><Button text="Student Uploads"/></Link>
            </div>
            
{/* 
            <div>
                <Link to='/elevator'><Button text="Elevator Pitch Form"/> </Link>
            </div>
            
            <div>
                <Link to='/adny-registration'><Button text="ADNY Form"/> </Link>
            </div>
             */}

            {/* <div>
                <Link to='/adny-satisfaction-survey'><Button text="ADNY Occupant Form"/></Link>    
            </div>

            <div>
                <Link to='/uploads'><Button text="Student Uploads"/></Link>
            </div> */}
        </div>
    //   </div>
  )
}
export default App