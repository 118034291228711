import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Translate } from 'react-auto-translate'
import './index.css'
import { GetCityByZipcode } from '../../../Apis/generalApis'

const SimpleRadio = (props) => {
    // console.log(props)
    const [formSelectedLanguage, setFormSelectedLanguage] = useState('')
    const handleChange = (event) => {
        // console.log("complete question data:------------------- ", props?.completeQuestionData, props?.formData);
        if (props?.completeQuestionData.dataSet?.formDataKey === "sex" && (props?.formData?.zipcode?.value).length >= 4 && (props?.formData?.zipcode?.value).length <= 5) {
            // console.log("simple radio:-----------------------", props?.completeQuestionData.dataSet?.formDataKey, props?.completeQuestionData);
            GetCityByZipcode(props?.formData?.zipcode?.value).then(res => {
                if (res.data) {
                console.log("city res:--------------------- ", res)
                const data = res.data
                if (data.length) {
                    const city = data[0]['attributes']['city']
                    const stateShortcode = data[0]['attributes']['state']
                    // console.log("city:--------------------------", city);
                    props.completeQuestionData.city = city
                    props.completeQuestionData.stateShortcode = stateShortcode
                }
                } else {
                props.completeQuestionData.city = ""
                }
            }).catch(error => {
                console.log("error while getting city from zipcode:", error);
            })
        }
        props.completeQuestionData['value'] = event.target.value
        props?.validateData()
    }

    let selectedLanguage = ''
    useEffect(() => {
        selectedLanguage = localStorage.getItem('newFormToLanguage') !== null ? localStorage.getItem('newFormToLanguage') : 'es'
        setFormSelectedLanguage(selectedLanguage)
    }, [])

    return (
        <div className={`single-group-child ${props?.completeQuestionData?.isVisible == false ? 'hide-question' : null} ${props?.className} ${props?.item['isError'] ? 'error-in-question' : ''}`}>
            <strong className='question-element'><Translate>{props?.item?.question}</Translate>{props?.item?.question === "Have you had a Section 3 job " ? <span><a href='https://www.nyc.gov/site/hpd/services-and-information/hud-section-3.page' target={'_blank'}>(<Translate>Section 3 Job Information</Translate>)</a><span>?</span></span> : props?.item?.question === "Do you live in a building managed by New York City Housing Authority " ? <span><a href='https://www.nyc.gov/site/nycha/about/about-nycha.page' target={'_blank'}>(<Translate>NYCHA Information</Translate>)</a><span>?</span></span> : null}{props?.item?.isRequired ? <span className='required'>*</span> : null}</strong>
            {(props?.item?.options).map((option, i) => {
                return (
                    <div className={`option-container ${props?.completeQuestionData?.isChildren ? 'align-start' : ''}`} key={i}>
                        <input type="radio" id={(option['line1'])}
                         name={props?.item?.groupName}
                         className={`${props?.completeQuestionData?.isChildren ? 'mt-3' : ''}`}
                         defaultValue={props?.completeQuestionData?.isChildren ? `${option['line1']} ${option['line2']} ${option['line3']}` : option} 
                         onChange={(event) => handleChange(event)}
                         checked={props?.completeQuestionData?.isChildren ? props?.completeQuestionData?.value === `${option['line1']} ${option['line2']} ${option['line3']}` : props?.completeQuestionData?.value === option}
                         disabled={props?.btnDisabled}
                         >
                         </input>
                         {props?.completeQuestionData?.isChildren ? 
                        <div className='mb-2'>
                            <p className='m-0 mb-3 fs-5'><strong><Translate>{option['line1']}</Translate></strong></p>
                            <p className='m-0 mb-3 fs-5'><Translate>{option['line2']}</Translate></p>
                            <p className='m-0 mb-3 fs-5'><Translate>{option['line3']}</Translate></p>
                        </div> : <span>{formSelectedLanguage === "en" ? option : <Translate>{option}</Translate>}</span>
                        }
                    </div>
                )
            })}
            {props?.item['isError'] ? <p className='error-message-container'><Translate>{props?.item['errorMessage']}</Translate></p> : null}
        </div>
    )
}

export default SimpleRadio

// We want one component for one question type. But in your case you make one one component for input, radio, checkbox and then using all these for making one question component