import React, { useEffect, useState } from 'react'
import Checkbox from '../Inputs/Checkbox'
import FormButton from '../Components/FormButton'
import '../Inputs.css'
import '../Radio.css'
import SimpleRadio from '../Components/Radio/SimpleRadio'
import { ToastContainer, toast } from 'react-toastify';
import SimpleRadioWithOther from '../Components/Radio/SimpleRadioWithOther/simpleRadioWithOther'
import SimpleCheckboxWithOther from '../Components/Checkbox/SimpleChcekBoxWithOther/simpleCheckboxWithOther'
import { EMAIL_REGEX } from '../utils/constants'
import FormSubmitted from './../assets/submitted.png'
import { Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Upload from '../Components/Upload/upload'
import Input from '../Inputs/Input'
import GroupedRadio from '../Inputs/GroupedRadio'
import SingleLineRadio from '../Inputs/SingleLineRadio'
import DOB from '../Inputs/Dob'
import moment from 'moment/moment'
import {NyserdaPreTrainingFormApplications,checkUserEmail} from '../Apis/nyserdaPreSurvey'
import {GetCoursesList} from '../Apis/generalApis'
import Dropdown from '../Inputs/Dropdown'
import CheckboxWithExpander from '../Components/Checkbox/CheckboxHavingChildrens'
import { Translate } from 'react-auto-translate'
import ReactGA from 'react-ga4'


const Pretraining = () => {

  const [loader, setLoader] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [coursesList, setCoursesList] = useState([])
  const [show, setShow] = useState(false)
  const [modalName, setModalName] = useState('')
  const [userExistsData, setUserExistsData] = useState({})

    // let dName = coursesList[0]?.fullname
    useEffect(() => {
        // setFormData({...formData, course: dName})
        document.title = "NYSERDA Pre Survey Form"
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });
        // try {
        //     GetCoursesList().then((res) => {
        //         if(res !== null) {
        //             setCoursesList(res.data)
        //         } else {
        //             setCoursesList([])
        //         }
        //     })
        // }catch (error) {
        //     setCoursesList([])
        //     toast.error(<Translate>Something went wrong</Translate>, {position: 'top-right', autoClose: 3000})
        // }
    }, [])


  const [formData, setFormData] = useState(
    {
        email: {value: '', dataSet: {question: "Email", isRequired: true, formDataKey: 'email', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', isEmail: true, userVerified: false,placeholder:'Email'}, 
         
        fName: {value: '', dataSet: {question: "First Name", isRequired: true, formDataKey: 'fName', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true,placeholder:'First Name'}, 

        lName: {value: '', dataSet: {question: "Last Name", isRequired: true, formDataKey: 'lName', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true,placeholder:'Last Name'},

        // dateOfBirth:{value:'',dataSet:{question:"Date of Birth",isRequired:true,formDataKey:'dateOfBirth', isError: false, errorMessage: ''}, type:'dob',disable:true},

        cellPhone: {value: '', dataSet: {question: "Cell Phone", isRequired: false, formDataKey: 'cellPhone', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'number', disable: true,placeholder:'Cell Phone Number'},
      
        homePhone: {value: '', dataSet: {question: "Home Phone", isRequired: false, formDataKey: 'homePhone', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'number', disable: true,placeholder:'Home Phone Number'},

        course: {value: '', dataSet: {question: "Course Title", isRequired: true, formDataKey: 'course', isError: false, errorMessage: ''}, type: 'dropdown', disable: true,placeholder:'Please Select Course'},

        dateOfCourse: {value:'',dataSet:{question:"Course Date",isRequired:true, formDataKey:'dateOfCourse',isError: false, errorMessage: ''}, type: 'dob', disable: true},

        TrainingOrganization: {value:'',dataSet:{question:"Training Organization",isRequired:true, formDataKey:'TrainingOrganization',isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true,placeholder:'Training Organization'},

        TrainerName: {value:'',dataSet:{question:"Trainer Name(s)",isRequired:true, formDataKey:'TrainerName',isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true,placeholder:'Trainer Name(s)'},

        isEmployed: {value: '', dataSet: {question: "Are you employed?", options: ["Yes", "No"], isRequired: true, groupName: 'isEmployed', formDataKey: 'isEmployed', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 
        
        isSelfEmployed: {value: '', dataSet: {question: "If employed, are you self-employed?", options: ["Yes", "No"], isRequired: true, groupName: 'isSelfEmployed', formDataKey: 'isSelfEmployed', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true, isVisible: false}, 
        
        employedWorkingType: {value: '', dataSet: {question: "If employed, are you working Full-Time or Part-Time?", options: ["Full-Time", "Part-Time"], isRequired: true, groupName: 'employedWorkingType', formDataKey: 'employedWorkingType', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true, isVisible: false}, 
        
        employedWorkingIn: {value: '', dataSet: {question: "If employed, are you working in a role and/or company related to clean energy?", options: ["Yes", "No"], isRequired: true, groupName: 'employedWorkingIn', formDataKey: 'employedWorkingIn', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true, isVisible: false}, 
        
        isStudiedCleanEnergy: {value: '', dataSet: {question: "Did you study in the clean energy or related field?", options: ["Yes", "No"], isRequired: true, groupName: 'isStudiedCleanEnergy', formDataKey: 'isStudiedCleanEnergy', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true, isVisible: false}, 
        
        IsWorking: {value: '', dataSet: {question: "Are you working in the area of your training or study?", options: ["Yes", "No"], isRequired: true, groupName: 'IsWorking', formDataKey: 'IsWorking', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true, isVisible: false}, 
        
        currentEmployer: {value: '', dataSet: {question: "If employed, who is your current employer?", isRequired: true, groupName: 'currentEmployer', formDataKey: 'currentEmployer', isError: false, errorMessage: ''}, type: 'simpleInput', disable: true, isVisible: false}, 
        
        companyJoiningDate: {value: '', dataSet: {question: "When did you start working with this company?", isRequired: true, groupName: 'companyJoiningDate', formDataKey: 'companyJoiningDate', isError: false, errorMessage: ''}, type: 'dob', disable: true, isVisible: false}, 

        // currentEmploymentStatus: {value: [], dataSet: {question: "Current Employement Status", options: ['Less than 6 months','More than 6 months'],choices:{'If Employed':'','If UnEmployed':''}, isRequired: true, groupName: 'currentEmploymentStatus', formDataKey: 'currentEmploymentStatus', isError: false, errorMessage: ''}, type: 'groupedradio', disable: true}, 

        employmentMangement: {value: '', dataSet: {question: "What is your current or last job title or position?", isRequired: true, formDataKey: 'employmentMangement', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', placeholder:'Job Title'},

        currentSalaryRange: {value: '', dataSet: {question: "What is your current salary range?", options: ['less than $30k','$30k - $44k','$45k - $59k','$60k - $74k','$75k - $89k','$90k - $105k', 'more than $105k'], isRequired: true, groupName: 'currentSalaryRange', formDataKey: 'currentSalaryRange', isError: false, errorMessage: ''}, type: 'singlelineRadio', disable: true},
        // skills_interests: {value: [], dataSet: {question: "For the items in each row please choose all that applies:", options: ['No Skill','Introductory','General','Advanced','Greatest Interest'],choices:{'Plumbing':'','Electrical':'','Architecture, Engineering or Related':'',"'HVAC Installation/Technician'":'',"Photovoltaic ('Photovoltaic (PV) Technician')":'','Solar Thermal Technician':'',"'Other: e.g. Storage, Heat Pump'":''}, isRequired: true, groupName: 'skills_interests', formDataKey: 'skills_interests', isError: false, errorMessage: ''}, type: 'groupedradio', disable: true}, 

        // experienceArea: {value: [], dataSet: {question: "Which of the following have you had experience working in? (check all that applies)", options: ['In energy efficiency areas (energy conservation, energy auditing, weatherization, etc.)',"In renewable energy areas (solar thermal, geothermal, photovoltaic ('Photovoltaic (PV) Technician') installation, fuel cells, etc.)",'None of the above'], isRequired: true, groupName: 'experienceArea', formDataKey: 'experienceArea', isError: false, errorMessage: ''}, type: 'simpleCheckbox', disable: true}, 
        // experienceInsideCleanEnergy: {value: '', dataSet: {question: "How many years of experience do you have working inside the clean energy industry?", options: ['None','1 year',"2 years","3 years","4 years","5 or more years"], isRequired: true, groupName: 'experienceInsideCleanEnergy', formDataKey: 'experienceInsideCleanEnergy', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 
        
        // experienceOutsideCleanEnergy: {value: '', dataSet: {question: "How many years of experience do you have working outside the clean energy industry?", options: ['None','1 year',"2 years","3 years","4 years","5 or more years"], isRequired: true, groupName: 'experienceOutsideCleanEnergy', formDataKey: 'experienceOutsideCleanEnergy', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 
        
        lookingEmployment: {value: '', dataSet: {question: "Are you looking for employment opportunities?", options: ["Yes", "No"], isRequired: true, groupName: 'lookingEmployment', formDataKey: 'lookingEmployment', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 
        // If above question is Yes ---------------------------------
        lookingEmploymentDetails: {value: '', dataSet: {question: "If yes, select which one fits you:", options: ["Full-Time", "Part-Time", "Both"], isRequired: true, groupName: 'lookingEmploymentDetails', formDataKey: 'lookingEmploymentDetails', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true, isVisible: false}, 

        openToIntership: {value: '', dataSet: {question: "Are you open to internship opportunities?", options: ["Yes", "No"], isRequired: true, groupName: 'openToIntership', formDataKey: 'openToIntership', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true, isVisible: false}, 

        haveSection3Job: {value: '', dataSet: {question: "Have you had a Section 3 job ", options: ["Yes", "No"], isRequired: true, groupName: 'haveSection3Job', formDataKey: 'haveSection3Job', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 

        isNYCHAmanagedResident: {value: '', dataSet: {question: "Do you live in a building managed by New York City Housing Authority ", options: ["Yes", "No"], isRequired: true, groupName: 'isNYCHAmanagedResident', formDataKey: 'isNYCHAmanagedResident', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 

        // careerSupport: {value: '', dataSet: {question: "If seeking employment, would you be interested in career services and job placement support?", options: ["Yes", "Maybe"], isRequired: true, groupName: 'careerSupport', formDataKey: 'careerSupport', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true, isVisible: false}, 

        // ------------------------------------------------------

        electricalEfficiencyExperience: {value: [], otherValue: '', dataSet: {question: "Do you have experience in these electrical efficiency areas? (Select all that apply to you)", options: [{option: "Lighting", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'lightingEea'}, {option: "HVAC", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'hvac'}, {option: "Motors and Drives", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'motorAndDrives'}, {option: "Appliances", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'appliances'}, {option: "None", children: [], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'noneOne'}, {option: "Other", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'otherEee'}], isRequired: true, groupName: 'electricalEfficiencyExperience', formDataKey: 'electricalEfficiencyExperience', isError: false, errorMessage: '', isNoneSelect: false, isOtherSelect: false}, type: 'checkboxWithExpander', disable: true}, 

        thermalEfficiencyExperience: {value: [], otherValue: '', dataSet: {question: "Do you have experience in these thermal efficiency areas? (Select all that apply to you)", options: [{option: "Heating", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'heating'}, {option: "Hot Water", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'hotWater'}, {option: "Building Envelope", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'buildingEnvelope'}, {option: "Combustion Appliances", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'combustionAppliances'}, {option: "None", children: [], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'noneTwo'}, {option: "Other", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'otherTea'}], isRequired: true, groupName: 'thermalEfficiencyExperience', formDataKey: 'thermalEfficiencyExperience', isError: false, errorMessage: '', isNoneSelect: false, isOtherSelect: false}, type: 'checkboxWithExpander', disable: true}, 

        electricalRenewableExperience: {value: [], otherValue: '', dataSet: {question: "Do you have experience in these electrical renewable areas? (Select all that apply to you)", options: [{option: "Solar PV", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'solarPv'}, {option: "Wind", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'wind'}, {option: "None", children: [], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'noneThree'}, {option: "Other", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'otherEre'}], isRequired: true, groupName: 'electricalRenewableExperience', formDataKey: 'electricalRenewableExperience', isError: false, errorMessage: '', isNoneSelect: false, isOtherSelect: false}, type: 'checkboxWithExpander', disable: true}, 

        thermalRenewableExperience: {value: [], otherValue: '', dataSet: {question: "Do you have experience in these thermal renewable areas? (Select all that apply to you)", options: [{option: "Solar Thermal", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'solartra'}, {option: "Geothermal", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'geoThermalTra'}, {option: "Wind", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'windTra'}, {option: "None", children: [], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'noneFour'}, {option: "Other", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'otherTra'}], isRequired: true, groupName: 'thermalRenewableExperience', formDataKey: 'thermalRenewableExperience', isError: false, errorMessage: '', isNoneSelect: false, isOtherSelect: false}, type: 'checkboxWithExpander', disable: true}, 
        
        tradesExperience: {value: [], otherValue: '', dataSet: {question: "Do you have experience in these trades? (Select all that apply to you)", options: [{option: "General Residential Construction", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'grctra'}, {option: "General Commercial Construction", children: ["No Skill", "Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'gcc'}, {option: "Plumbing", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'plumbingTra'}, {option: "Electrical", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'electricalTra'}, {option: "Architecture, Engineering or Related", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'aerTra'}, {option: "None", children: [], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'noneFive'}], isRequired: true, groupName: 'tradesExperience', formDataKey: 'tradesExperience', isError: false, errorMessage: '', isNoneSelect: false, isOtherSelect: false}, type: 'checkboxWithExpander', disable: true}, 

        interestedJobRoles: {value: [], otherValue: '', dataSet: {question: "Please select the job roles that interest you. (Select all that apply to you)", options: ['Sales & Outreach','Auditor & Junior Engineer','Finance & Incentive Administrator',"Project Management & Project Coordination",'Design & Engineering','Installation & Contracting','Site Inspector','Operations & Maintenance','Clean Energy Instructor', 'No Preference', 'Other'], isRequired: true, groupName: 'interestedJobRoles', formDataKey: 'interestedJobRoles', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: true}, 
        
        interest: {value: [], otherValue: '', dataSet: {question: "Please select all that interest you:", options: ['Energy Efficiency','Renewable Energy',"General Residential Construction","General Commercial Construction","Building Shell/Envelope Improvements","Plumbing","Electrical","Architecture, Engineering or Related","HVAC Installation/Technician","Photovoltaic (PV) Technician","Solar Thermal Technician","No Preference", "Other"], isRequired: true, groupName: 'interest', formDataKey: 'interest', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: true}, 
 
        reasonEnroll: {value: [], otherValue: '', dataSet: {question: "What is your main educational objective for taking this class: (Select the one that most applies to you)", options:["Certification required to participate in an incentive program","To prepare for industry recognized certifications and/or receive CEUs","Maintain a license or certification (required professional CEU)","Prepare for an apprenticeship/trades program","My employer recommended it","Help me get a job in construction or clean energy field","Career advancement (obtain a promotion or get a better job than the one I have now)","It is a part of a legally mandated program","To meet specific needs indicated by a client or customer","Required prerequisite for other training I want to take","None of the above", "Other"], isRequired: true, groupName: 'reasonEnroll', formDataKey: 'reasonEnroll', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: true}, 
        
        // currentEmploymentType: {value: '', dataSet: {question: "What is your current employment status? (Select the one that most applies to you Possible Answer)", options: ["Employed Full-time: 31 hours or more per week in your field of study or training", "Employed Part-time: 30 hours or less per week", "Under-employed: Working less than 20 hours per week or not working in the area of your study or training", "Vocational/Technical School", "Unemployed"], isRequired: true, groupName: 'currentEmploymentType', formDataKey: 'currentEmploymentType', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 
        
        // careerGoals: {value: [], dataSet: {question: "Which of the following have you had experience working in? (check all that applies)", options:careergoalsarr, isRequired: true, groupName: 'careerGoals', formDataKey: 'careerGoals', isError: false, errorMessage: ''}, type: 'simpleCheckbox', disable: true}, 
        
        educationLevel: {value: '', dataSet: {question: "What is the highest level of education you have completed? (Select the one that most applies to you)", options: ["Have not completed High School", "High School / HSE / GED", "Associates degree", "Bachelor's degree", "Graduate degree","Post Graduate degree such as Master or Doctorate"], isRequired: true, groupName: 'educationLevel', formDataKey: 'educationLevel', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 
        
        participatedVocationalProgram: {value: '', dataSet: {question: "Have you ever participated or are you currently participating in a vocational program?", options: ["Yes, I am currently", "Yes, I have in the past", "Yes, I have in the past and yes I am currently", "No"], isRequired: true, groupName: 'participatedVocationalProgram', formDataKey: 'participatedVocationalProgram', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 

        // checkAdditionalList: {value: [], dataSet: {question: "Check all that apply:", options: ['Immigrant',"Refugee",'English as a second language (ESL)','1st Generation College','2nd Generation College'], isRequired: true, groupName: 'interestedJobRoles', formDataKey: 'interestedJobRoles', isError: false, errorMessage: ''}, type: 'simpleCheckbox', disable: true}, 
        
        courselanguage: {value: '', dataSet: {question: "If we could offer our course in another language, which would you prefer? (Select the one that most applies to you)", options: ["English", "Spanish", "Mandarin", "Russian", "Polish", "Other"], isRequired: true, groupName: 'courselanguage', formDataKey: 'courselanguage', isError: false, errorMessage: ''}, type: 'simpleRadioWithOther', disable: true}, 
        
        // awareNYSDeptLCC: {value: '', dataSet: {question: "How likely are you to recommend/share this training to a colleague or friend? (with 5 being most likely to recommend)", options: ["Yes, I have heard of them", "Yes, I have heard of them, but don’t really know what they are", "No, I have not heard of them"], isRequired: true, groupName: 'awareNYSDeptLCC', formDataKey: 'awareNYSDeptLCC', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 

        // registeredNYSDeptLCC: {value: '', dataSet: {question: "Have you registered with a local NYS Department of Labor Career Center?", options: ["Yes", "No, and I don’t currently plan to", "No, but I plan to","I am not sure"], isRequired: true, groupName: 'registeredNYSDeptLCC', formDataKey: 'registeredNYSDeptLCC', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 

        infoSourceOfJobOpp:{value: [], otherValue: '', dataSet: {question: "What information source do you use to find job opportunities?", options:[`Clean Energy Academy Job Board (http://cleanenergyacademy.org)`,'NYS Department of Labor Career Center','Training provider','“Help wanted” ads in a newspaper','Internet (e.g. Monster.com)','Guidance Counselor','Networking',"HRA / Workforce One",'I am not sure','Other'], isRequired: true, groupName: 'infoSourceOfJobOpp', formDataKey: 'infoSourceOfJobOpp', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: true}, 
        
        // hopeFromCourse:{value: [], otherValue: '', dataSet: {question: "Which of the following statements best describes what you hope to accomplish from this training? (check all that apply)", options:['Acquire new skills','Improve skills for promotion','Change field of employment','Obtain employment','I am not sure','Other'], isRequired: true, groupName: 'hopeFromCourse', formDataKey: 'hopeFromCourse', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: true}, 
        
        expectationFromCourse: {value: [], otherValue: '', dataSet: {question: "Which response best describes your expectations for this course, given your experience and career goals? (Select all that apply to you) ", options:['The course will teach me new things I want to learn','The course will provide a refresher on things I have learned before','The course may be over my head','The course may be below my level','The course is definitely over my head','The course is definitely below my level','I am not sure', 'Other'], isRequired: true, groupName: 'expectationFromCourse', formDataKey: 'expectationFromCourse', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: true}, 

        // paymentBy: {value: '', dataSet: {question: "Who is paying for part or all of the cost of this training course? (check all that apply)", options: ["Don’t know","Employer","I am","NYS Department of Labor","NYSERDA","Other"], isRequired: true, groupName: 'paymentBy', formDataKey: 'paymentBy', isError: false, errorMessage: ''}, type: 'radioWithOther', disable: true}, 

        // gradeForTrainingSession: {value: {}, dataSet: {question: "On a scale from 0 to 10 where 0 means “not at all satisfied” and 10 means “extremely satisfied” please rate the extent of your satisfaction with the following aspects of this training session?", options: ['1','2','3','4','5','6','7','8','9','10'],choices:{'Convenience of the location':'','Convenience of the time and training offered':'','Your cost to attend this training':''}, isRequired: true, groupName: 'gradeForTrainingSession', formDataKey: 'gradeForTrainingSession', isError: false, errorMessage: ''}, type: 'groupedradio', disable: true},

        



        idFile:{value:'', dataSet:{question:"Please provide a valid form of identification such as a Driver's License, State ID, Passport, School ID, or Foreign Passport:",isRequired: false, formDataKey: 'idFile', isError: false, errorMessage: '',allowedFileType: ['png', 'pdf', 'jpg','jpeg'], isOption:true}, disable: true, type: 'upload', inputType: 'text'},
      }
  )


  const [IsLoading, setIsLoading] = useState(false)
  const validateData = () => {
    let functionReturn = true
    let validateFormData = formData
    // console.log("email: ", email, formData['email']['dataSet']['isRequired'] && (formData['email']['dataSet']['formDataKey'] === "email"), validateFormData);

    Object.keys(validateFormData).forEach(eachQuestion => {
      // Checking Required ------------------------------------------------------------------------------------------------------------------
      // console.log(validateFormData[eachQuestion]['dataSet'].isRequired)
        if(validateFormData[eachQuestion]['dataSet'].isRequired && (validateFormData[eachQuestion]['type']) ==='groupedradio'){
          const l=[]
          Object.keys(validateFormData[eachQuestion]['dataSet']['choices']).map((i)=>{
            if(validateFormData[eachQuestion]['dataSet']['choices'][i]!=''){
              l.push(validateFormData[eachQuestion]['dataSet']['choices'][i])
            }
          })
          if(l.length != Object.keys(validateFormData[eachQuestion]['dataSet']['choices']).length){
            validateFormData[eachQuestion]['dataSet']['isError'] = true
            validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
            functionReturn = false
          }
          else{
            validateFormData[eachQuestion]['dataSet']['isError'] = false
            validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
          }
        } else if (validateFormData[eachQuestion]['dataSet'].isRequired && (validateFormData[eachQuestion]['type']) === 'checkboxWithExpander') {
          const valueArray = validateFormData[eachQuestion]['value']
          if (valueArray.length !== 0) {
            for (let i = 0 ; i < valueArray.length; i++) {
              if (valueArray[i]['value'] === "None") {
                validateFormData[eachQuestion]['dataSet']['isError'] = false
                validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
                if (valueArray[i]['value'] === "None") {
                  validateFormData[eachQuestion]['value'] = [{value: 'None', subValue: ''}]
                }
              } else {
                if ((valueArray[i]['subValue']).length > 0) {
                  if (valueArray[i]['value'] === "Other") {
                    if ((validateFormData[eachQuestion]['dataSet']['isOtherSelect'] 
                    // && validateFormData[eachQuestion]['otherValue']
                    )) {
                      validateFormData[eachQuestion]['dataSet']['isError'] = false
                      validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
                    } else {
                      validateFormData[eachQuestion]['dataSet']['isError'] = true
                      validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
                    }
                  } else {
                    if (validateFormData[eachQuestion]['dataSet']['isOtherSelect'] === false) {
                      validateFormData[eachQuestion]['dataSet']['isError'] = false
                      validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
                    } else {
                      if ((validateFormData[eachQuestion]['dataSet']['isOtherSelect'] 
                      // && validateFormData[eachQuestion]['otherValue']
                      )) {
                        validateFormData[eachQuestion]['dataSet']['isError'] = false
                        validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
                      } else {
                        validateFormData[eachQuestion]['dataSet']['isError'] = true
                        validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
                      }
                    }
                  }
                } else {
                  validateFormData[eachQuestion]['dataSet']['isError'] = true
                  validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
                  functionReturn = false
                }
              }
            }
          } else {
            validateFormData[eachQuestion]['dataSet']['isError'] = true
            validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
            functionReturn = false
          }
        } else if (validateFormData[eachQuestion]['dataSet'].isRequired && (validateFormData[eachQuestion]['type'] === 'dropdown')) {
          const selectedValue = validateFormData[eachQuestion]['value']
          if ((`${selectedValue}`).includes('WestCop')) {
            validateFormData['idFile']['dataSet']['isRequired'] = true
          } else {
            validateFormData['idFile']['dataSet']['isRequired'] = false
          }
        } 
        else if(validateFormData[eachQuestion]['dataSet']['isRequired'] && (validateFormData[eachQuestion]['value']) <= 0){
          
          validateFormData[eachQuestion]['dataSet']['isError'] = true
          validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
          functionReturn = false
        } else {
          validateFormData[eachQuestion]['dataSet']['isError'] = false
          validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
        }
      // Checking Required ------------------------------------------------------------------------------------------------------------------
      // Push the children questions into the formdata
      // if (!IsLookingEmployed) {
        if (eachQuestion === 'lookingEmployment') {
          if (validateFormData[eachQuestion]['value'] === "Yes") {
            // setIsLookingEmployed(true)
            validateFormData['lookingEmploymentDetails']['isVisible'] = true
            validateFormData['openToIntership']['isVisible'] = true
            // validateFormData['careerSupport']['isVisible'] = true

            validateFormData['lookingEmploymentDetails']['dataSet']['isRequired'] = true
            validateFormData['openToIntership']['dataSet']['isRequired'] = true
            // validateFormData['careerSupport']['dataSet']['isRequired'] = true
            
            // isStudiedCleanEnergy
            // IsWorking
            // companyJoiningDate
            
          } else {
            validateFormData['lookingEmploymentDetails']['isVisible'] = false
            validateFormData['lookingEmploymentDetails']['value'] = ''
            validateFormData['lookingEmploymentDetails']['dataSet']['isRequired'] = false 
            
            validateFormData['openToIntership']['isVisible'] = false
            validateFormData['openToIntership']['value'] = ''
            validateFormData['openToIntership']['dataSet']['isRequired'] = false 

            // validateFormData['careerSupport']['isVisible'] = false
            // validateFormData['careerSupport']['value'] = ''
            // validateFormData['careerSupport']['dataSet']['isRequired'] = false 

          }
        }
        
        if (eachQuestion === "isEmployed") {
          if (validateFormData[eachQuestion]['value'] === "Yes") {
            validateFormData['isSelfEmployed']['isVisible'] = true
            validateFormData['employedWorkingType']['isVisible'] = true
            validateFormData['employedWorkingIn']['isVisible'] = true
            validateFormData['currentEmployer']['isVisible'] = true
            validateFormData['isSelfEmployed']['dataSet']['isRequired'] = true
            validateFormData['employedWorkingType']['dataSet']['isRequired'] = true
            validateFormData['employedWorkingIn']['dataSet']['isRequired'] = true
            validateFormData['currentEmployer']['dataSet']['isRequired'] = true
            
            validateFormData['isStudiedCleanEnergy']['isVisible'] = true
            validateFormData['IsWorking']['isVisible'] = true
            validateFormData['companyJoiningDate']['isVisible'] = true
    
            validateFormData['isStudiedCleanEnergy']['dataSet']['isRequired'] = true
            validateFormData['IsWorking']['dataSet']['isRequired'] = true
            validateFormData['companyJoiningDate']['dataSet']['isRequired'] = true
          } else {
            validateFormData['isSelfEmployed']['isVisible'] = false
            validateFormData['isSelfEmployed']['value'] = ''
            validateFormData['isSelfEmployed']['dataSet']['isRequired'] = false

            validateFormData['employedWorkingType']['isVisible'] = false
            validateFormData['employedWorkingType']['value'] = ''
            validateFormData['employedWorkingType']['dataSet']['isRequired'] = false

            validateFormData['employedWorkingIn']['isVisible'] = false
            validateFormData['employedWorkingIn']['value'] = ''
            validateFormData['employedWorkingIn']['dataSet']['isRequired'] = false

            validateFormData['currentEmployer']['isVisible'] = false
            validateFormData['currentEmployer']['value'] = ''
            validateFormData['currentEmployer']['dataSet']['isRequired'] = false
            
            validateFormData['isStudiedCleanEnergy']['isVisible'] = false
            validateFormData['isStudiedCleanEnergy']['value'] = ''
            validateFormData['isStudiedCleanEnergy']['dataSet']['isRequired'] = false 
    
            validateFormData['IsWorking']['isVisible'] = false
            validateFormData['IsWorking']['value'] = ''
            validateFormData['IsWorking']['dataSet']['isRequired'] = false 
    
            validateFormData['companyJoiningDate']['isVisible'] = false
            validateFormData['companyJoiningDate']['value'] = ''
            validateFormData['companyJoiningDate']['dataSet']['isRequired'] = false 
          }
        }
      // }
    })

    console.log("validateFormData: ", validateFormData);

    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
    setFormData(validateFormData)
    return functionReturn
  }
      
  const handleReloadForm = () => {
    setModalName('Thank You')
    setTimeout(() => {
      window.location.reload()
    }, 3000)
  }
  const handleCancel = () => {
    setShow(false)
  }

  const handleSubmit = () => {
    if (validateData()) {
      let dateString = new Date();
      dateString = moment(dateString).format('YYYY-MM-DD');
      console.log("formData: ", userExistsData);
      setLoader(true)

      // Make Data For Question Type == CheckboxWithExpander

      let electricalEfficiencyExperience = formData.electricalEfficiencyExperience.value
      let updatedAnswerElectricalEfficiencyExperience = {} 
      electricalEfficiencyExperience.forEach((item, i) => {
        updatedAnswerElectricalEfficiencyExperience[i] = item
      })

      let thermalEfficiencyExperience = formData.thermalEfficiencyExperience.value
      let updatedAnswerThermalEfficiencyExperience = {} 
      thermalEfficiencyExperience.forEach((item, i) => {
        updatedAnswerThermalEfficiencyExperience[i] = item
      })

      let electricalRenewableExperience = formData.electricalRenewableExperience.value
      let updatedAnswerElectricalRenewableExperience = {} 
      electricalRenewableExperience.forEach((item, i) => {
        updatedAnswerElectricalRenewableExperience[i] = item
      })

      let thermalRenewableExperience = formData.thermalRenewableExperience.value
      let updatedAnswerThermalRenewableExperience = {} 
      thermalRenewableExperience.forEach((item, i) => {
        updatedAnswerThermalRenewableExperience[i] = item
      })
     
      let tradesExperience = formData.tradesExperience.value
      let updatedAnswerTradesExperience = {} 
      tradesExperience.forEach((item, i) => {
        updatedAnswerTradesExperience[i] = item
      })
      // thermalEfficiencyExperience
      // electricalRenewableExperience
      // thermalRenewableExperience
      NyserdaPreTrainingFormApplications({data: {
        email: formData['email']['value'],
        fName: formData['fName']['value'],
        lName: formData['lName']['value'],
        // dateOfBirth:formData['dateOfBirth']['value'],
        cellPhone: formData['cellPhone']['value'],
        homePhone:formData['homePhone']['value'],
        course:formData['course']['value'],
        dateOfCourse:formData['dateOfCourse']['value'],
        TrainingOrganization:formData['TrainingOrganization']['value'],
        TrainerName:formData['TrainerName']['value'],
        // "currentEmploymentStatus":formData['currentEmploymentStatus']['dataSet']['choices'],
        employmentMangement:formData['employmentMangement']['value'],
        // "skills_interests":{'Plumbing':formData['skills_interests']['dataSet']['choices']['Plumbing'],'Electrical':formData['skills_interests']['dataSet']['choices']['Electrical'],'Architecture, Engineering or Related':formData['skills_interests']['dataSet']['choices']['Architecture, Engineering or Related'],'HVAC Installation/Technician':formData['skills_interests']['dataSet']['choices']['HVAC Installation/Technician'],'Photovoltaic (PV) Technician':formData['skills_interests']['dataSet']['choices']["Photovoltaic ('Photovoltaic (PV) Technician')"],'Solar Thermal Technician':formData['skills_interests']['dataSet']['choices']['Solar Thermal Technician'],'Other: e.g. Storage, Heat Pump':formData['skills_interests']['dataSet']['choices']['Other: e.g. Storage, Heat Pump'],},
        // "experienceArea":(formData['experienceArea']['value']).map(item => item).join(','),
        educationLevel:formData['educationLevel']['value'],
        // "currentEmploymentType":formData['currentEmploymentType']['value'],
        reasonEnroll:formData['reasonEnroll']['value'].map(item => item).join(',') + formData['reasonEnroll']['otherValue'],
        // awareNYSDeptLCC:formData['awareNYSDeptLCC']['value'],
        // registeredNYSDeptLCC:formData['registeredNYSDeptLCC']['value'],
        infoSourceOfJobOpp:(formData['infoSourceOfJobOpp']['value']).map(item => item).join(','),
        // "hopeFromCourse":(formData['hopeFromCourse']['value']).map(item => item).join(','),
        expectationFromCourse:(formData['expectationFromCourse']['value']).map(item => item).join(','),
        // paymentBy:formData['paymentBy']['value'],
        versionName: 'new_2023',
        studentMoodleId: formData?.email?.studentMoodleId || '',
        // "gradeForTrainingSession":formData['gradeForTrainingSession']['dataSet']['choices'],
        currentSalaryRange:formData['currentSalaryRange']['value'],
        idFile:formData['idFile']['value'],
        isEmployed: formData['isEmployed']['value'],
        isSelfEmployed: formData['isSelfEmployed']['value'],
        employedWorkingType: formData['employedWorkingType']['value'],
        employedWorkingIn: formData['employedWorkingIn']['value'],
        isStudiedCleanEnergy: formData['isStudiedCleanEnergy']['value'],
        IsWorking: formData['IsWorking']['value'],
        currentEmployer: formData['currentEmployer']['value'],
        companyJoiningDate: formData['companyJoiningDate']['value'],
        employmentMangement: formData['employmentMangement']['value'],
        educationLevel: formData['educationLevel']['value'],
        // reasonEnroll: formData['reasonEnroll']['value'],
        // awareNYSDeptLCC: formData['awareNYSDeptLCC']['value'],
        // registeredNYSDeptLCC: formData['registeredNYSDeptLCC']['value'],
        // infoSourceOfJobOpp: formData['infoSourceOfJobOpp']['value'],
        // expectationFromCourse: formData['expectationFromCourse']['value'],
        // paymentBy: formData['paymentBy']['value'],
        currentSalaryRange: formData['currentSalaryRange']['value'],
        // experienceInsideCleanEnergy: formData['experienceInsideCleanEnergy']['value'],
        // experienceOutsideCleanEnergy: formData['experienceOutsideCleanEnergy']['value'],
        lookingEmployment: formData['lookingEmployment']['value'],
        lookingEmploymentDetails: formData['lookingEmploymentDetails']['value'],
        openToIntership: formData['openToIntership']['value'],
        // careerSupport: formData['careerSupport']['value'],
        electricalEfficiencyExperience: {...updatedAnswerElectricalEfficiencyExperience, otherValue: formData.electricalEfficiencyExperience.otherValue},
        thermalEfficiencyExperience: {...updatedAnswerThermalEfficiencyExperience, otherValue: formData.thermalEfficiencyExperience.otherValue},
        electricalRenewableExperience: {...updatedAnswerElectricalRenewableExperience, otherValue: formData.electricalRenewableExperience.otherValue},
        thermalRenewableExperience: {...updatedAnswerThermalRenewableExperience, otherValue: formData.thermalRenewableExperience.otherValue},
        tradesExperience: {...updatedAnswerTradesExperience, otherValue: formData.tradesExperience.otherValue},
        interestedJobRoles: (formData['interestedJobRoles']['value']).map(item => item).join(','),
        interest: (formData['interest']['value']).map(item => item).join(','),
        // checkAdditionalList: (formData['checkAdditionalList']['value']).map(item => item).join(','),
        participatedVocationalProgram: formData['participatedVocationalProgram']['value'],
        haveSection3Job: formData['haveSection3Job']['value'],
        isNYCHAmanagedResident: formData['isNYCHAmanagedResident']['value'],
        courselanguage: formData['courselanguage']['value'],
        formLanguage: localStorage.getItem('newFormFromLanguage') || 'en'
      }}).then(res => {
        if (res !== null) {
          setShow(true)
          setModalName("Verify Your Email")
          setLoader(false)
          toast.success(<Translate>Application Submitted Successfully</Translate>)
          handleReloadForm()
        }
      }).catch(error => {
        console.log("error: ", error);
        toast.error(<Translate>Something went wrong</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
      })
    
    } else {
      toast.error(<Translate>{'Please Fill All The Required Fields'}</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
    }
    console.log(formData)
  }

  // Prefil Form Data
  const prefillApplicationForm = (data) => {
    const userData = data.attributes
    console.log("prefillApplicationForm: ", userData);
    // console.log("questionOptions: ", questionOptions);

    setFormData({...formData, 
      fName: {...formData['fName'], 'value': userData.fName, dataSet: {...formData['fName']['dataSet'], isError: false, errorMessage: ''}},
      lName: {...formData['lName'], 'value': userData.lName, dataSet: {...formData['lName']['dataSet'], isError: false, errorMessage: ''}},
      // dateOfBirth: {...formData['dateOfBirth'], 'value': userData.dateOfBirth, dataSet: {...formData['dateOfBirth']['dataSet'], isError: false, errorMessage: ''}},
      // cellPhone: {...formData['cellPhone'], 'value': userData.cellPhone, dataSet: {...formData['cellPhone']['dataSet'], isError: false, errorMessage: ''}},
      // homePhone: {...formData['homePhone'], 'value': userData.homePhone, dataSet: {...formData['homePhone']['dataSet'], isError: false, errorMessage: ''}},
      // course: {...formData['course'], 'value': userData.course, dataSet: {...formData['course']['dataSet'], isError: false, errorMessage: ''}},
      // dateOfCourse: {...formData['dateOfCourse'], 'value': userData.dateOfCourse, dataSet: {...formData['dateOfCourse']['dataSet'], isError: false, errorMessage: ''}},
      // TrainingOrganization: {...formData['TrainingOrganization'], 'value': userData.TrainingOrganization, dataSet: {...formData['TrainingOrganization']['dataSet'], isError: false, errorMessage: ''}},
      // TrainerName: {...formData['TrainerName'], 'value': userData.TrainerName, dataSet: {...formData['TrainerName']['dataSet'], isError: false, errorMessage: ''}},
      // isEmployed: {...formData['isEmployed'], 'value': userData.isEmployed ? userData.isEmployed : '', dataSet: {...formData['isEmployed']['dataSet'], isError: false, errorMessage: ''}},
      // isSelfEmployed: {...formData['isSelfEmployed'], 'value': userData.isSelfEmployed ? userData.isSelfEmployed : '', dataSet: {...formData['isSelfEmployed']['dataSet'], isError: false, errorMessage: ''}, isVisible: formData['isEmployed']['value'].length > 0 && formData['isEmployed']['value'] !== "Yes" ? 'Yes' : 'No'},
      // employedWorkingType: {...formData['employedWorkingType'], 'value': userData.employedWorkingType, dataSet: {...formData['employedWorkingType']['dataSet'], isError: false, errorMessage: ''}, isVisible: formData['isEmployed']['value'].length > 0 && formData['isEmployed']['value'] !== "Yes" ? 'Yes' : 'No'},
      // employedWorkingIn: {...formData['employedWorkingIn'], 'value': userData.employedWorkingIn, dataSet: {...formData['employedWorkingIn']['dataSet'], isError: false, errorMessage: ''}, isVisible: formData['isEmployed']['value'].length > 0 && formData['isEmployed']['value'] !== "Yes" ? 'Yes' : 'No'},
      // currentEmployer: {...formData['currentEmployer'], 'value': userData.currentEmployer, dataSet: {...formData['currentEmployer']['dataSet'], isError: false, errorMessage: ''}, isVisible: formData['isEmployed']['value'].length > 0 && formData['isEmployed']['value'] !== "Yes" ? 'Yes' : 'No'},
      // isStudiedCleanEnergy: {...formData['isStudiedCleanEnergy'], 'value': userData.isStudiedCleanEnergy, dataSet: {...formData['isStudiedCleanEnergy']['dataSet'], isError: false, errorMessage: ''}},
      // IsWorking: {...formData['IsWorking'], 'value': userData.IsWorking, dataSet: {...formData['IsWorking']['dataSet'], isError: false, errorMessage: ''}},
      // companyJoiningDate: {...formData['companyJoiningDate'], 'value': userData.companyJoiningDate, dataSet: {...formData['companyJoiningDate']['dataSet'], isError: false, errorMessage: ''}},
      // employmentMangement: {...formData['employmentMangement'], 'value': userData.employmentMangement, dataSet: {...formData['employmentMangement']['dataSet'], isError: false, errorMessage: ''}},
      // educationLevel: {...formData['educationLevel'], 'value': userData.educationLevel, dataSet: {...formData['educationLevel']['dataSet'], isError: false, errorMessage: ''}},
      // reasonEnroll: {...formData['reasonEnroll'], 'value': userData['reasonEnroll'] ? (userData['reasonEnroll']).split(',').map((item) => (item).trim()) : [], dataSet: {...formData['reasonEnroll']['dataSet'], isError: false, errorMessage: ''}},
      // // awareNYSDeptLCC: {...formData['awareNYSDeptLCC'], 'value': userData.awareNYSDeptLCC, dataSet: {...formData['awareNYSDeptLCC']['dataSet'], isError: false, errorMessage: ''}},
      // // registeredNYSDeptLCC: {...formData['registeredNYSDeptLCC'], 'value': userData.registeredNYSDeptLCC, dataSet: {...formData['registeredNYSDeptLCC']['dataSet'], isError: false, errorMessage: ''}},
      // infoSourceOfJobOpp: {...formData['infoSourceOfJobOpp'], 'value': userData['infoSourceOfJobOpp'] ? (userData['infoSourceOfJobOpp']).split(',').map((item) => (item).trim()) : [], dataSet: {...formData['infoSourceOfJobOpp']['dataSet'], isError: false, errorMessage: ''}},
      // expectationFromCourse: {...formData['expectationFromCourse'], 'value': userData['expectationFromCourse'] ? (userData['expectationFromCourse']).split(',').map((item) => (item).trim()) : [], dataSet: {...formData['expectationFromCourse']['dataSet'], isError: false, errorMessage: ''}},
      // // paymentBy: {...formData['paymentBy'], 'value': userData.paymentBy, dataSet: {...formData['paymentBy']['dataSet'], isError: false, errorMessage: ''}},
      // currentSalaryRange: {...formData['currentSalaryRange'], 'value': userData.currentSalaryRange, dataSet: {...formData['currentSalaryRange']['dataSet'], isError: false, errorMessage: ''}},
      // // experienceInsideCleanEnergy: {...formData['experienceInsideCleanEnergy'], 'value': userData.experienceInsideCleanEnergy, dataSet: {...formData['experienceInsideCleanEnergy']['dataSet'], isError: false, errorMessage: ''}},
      // // experienceOutsideCleanEnergy: {...formData['experienceOutsideCleanEnergy'], 'value': userData.experienceOutsideCleanEnergy, dataSet: {...formData['experienceOutsideCleanEnergy']['dataSet'], isError: false, errorMessage: ''}},
      // lookingEmployment: {...formData['lookingEmployment'], 'value': userData.lookingEmployment, dataSet: {...formData['lookingEmployment']['dataSet'], isError: false, errorMessage: ''}},
      // lookingEmploymentDetails: {...formData['lookingEmploymentDetails'], 'value': userData.lookingEmploymentDetails, dataSet: {...formData['lookingEmploymentDetails']['dataSet'], isError: false, errorMessage: ''}, isVisible: formData['lookingEmployment']['value'].length > 0},
      // openToIntership: {...formData['openToIntership'], 'value': userData.openToIntership, dataSet: {...formData['openToIntership']['dataSet'], isError: false, errorMessage: ''}, isVisible: formData['lookingEmployment']['value'].length > 0},
      // // careerSupport: {...formData['careerSupport'], 'value': userData.careerSupport, dataSet: {...formData['careerSupport']['dataSet'], isError: false, errorMessage: ''}, isVisible: formData['lookingEmployment']['value'].length > 0},
      // electricalEfficiencyExperience: {...formData['electricalEfficiencyExperience'], 'value': userData['electricalEfficiencyExperience'] ? (userData['electricalEfficiencyExperience']).split(',').map((item) => (item).trim()) : [], dataSet: {...formData['electricalEfficiencyExperience']['dataSet'], isError: false, errorMessage: ''}},
      // thermalEfficiencyExperience: {...formData['mName'], 'value': userData['thermalEfficiencyExperience'] ? (userData['thermalEfficiencyExperience']).split(',').map((item) => (item).trim()) : [], dataSet: {...formData['thermalEfficiencyExperience']['dataSet'], isError: false, errorMessage: ''}},
      // electricalRenewableExperience: {...formData['electricalRenewableExperience'], 'value': userData['electricalRenewableExperience'] ? (userData['electricalRenewableExperience']).split(',').map((item) => (item).trim()) : [], dataSet: {...formData['electricalRenewableExperience']['dataSet'], isError: false, errorMessage: ''}},
      // thermalRenewableExperience: {...formData['thermalRenewableExperience'], 'value': userData['thermalRenewableExperience'] ? (userData['thermalRenewableExperience']).split(',').map((item) => (item).trim()) : [], dataSet: {...formData['thermalRenewableExperience']['dataSet'], isError: false, errorMessage: ''}},
      // interestedJobRoles: {...formData['interestedJobRoles'], 'value': userData['interestedJobRoles'] ? (userData['interestedJobRoles']).split(',').map((item) => (item).trim()) : [], dataSet: {...formData['interestedJobRoles']['dataSet'], isError: false, errorMessage: ''}},
      // participatedVocationalProgram: {...formData['participatedVocationalProgram'], 'value': userData.participatedVocationalProgram, dataSet: {...formData['participatedVocationalProgram']['dataSet'], isError: false, errorMessage: ''}},
      // courselanguage: {...formData['courselanguage'], 'value': userData.courselanguage, dataSet: {...formData['courselanguage']['dataSet'], isError: false, errorMessage: ''}},
      // idFile: {...formData['idFile'], 'value': userData.idFile, dataSet: {...formData['idFile']['dataSet'], isError: false, errorMessage: ''}},
    })
  }

  const getContent = (questionObject, singleOrMulti) => {
    // console.log("questionObject: ", questionObject.type);

    switch(questionObject.type) {

      case "simpleRadio":  return (
        <SimpleRadio className={singleOrMulti} item={questionObject['dataSet']} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
      )

      case "simpleCheckbox":  return(
        <Checkbox className={singleOrMulti} item={questionObject['dataSet']} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
      )
      
      case "simpleInput":  return(
        <Input className={singleOrMulti} setCoursesList={setCoursesList} setUserExistsData={setUserExistsData} item={questionObject} btnDisabled={btnDisabled} formType="preNysedra" setBtnDisabled={setBtnDisabled} prefillApplicationForm={prefillApplicationForm}  completeQuestionData={questionObject} formData={formData} validateData={validateData} setFormData={setFormData}/>
      )
      
      case "radioWithOther":  return (
          <SimpleRadioWithOther className={singleOrMulti} item={questionObject} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
        )

      case "checkboxWithOther":  return (
          <SimpleCheckboxWithOther className={singleOrMulti} item={questionObject} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
        )
      
      case "upload":  return (
          <Upload className={singleOrMulti} file={formData.idFile.value} setLoader={setLoader} item={questionObject} email={formData.email.value} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
        )

      case "groupedradio":return(
        <GroupedRadio className={singleOrMulti} item={questionObject} completeQuestionData={questionObject} btnDisabled={btnDisabled} validateData={validateData}/>
      )

      case "singlelineRadio":return(
        <SingleLineRadio className={singleOrMulti} item={questionObject} completeQuestionData={questionObject} btnDisabled={btnDisabled} validateData={validateData}/>
      )

      case "dropdown":return(
        <Dropdown className={singleOrMulti} coursesList={coursesList} item={questionObject} completeQuestionData={questionObject} btnDisabled={btnDisabled} validateData={validateData}/>
      )

      case "dob":  return (
        <DOB className={singleOrMulti} item={questionObject}  btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
        // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
      )
        
      case "simpleRadioWithOther":return(
        <SimpleRadioWithOther className={singleOrMulti} coursesList={coursesList} item={questionObject} completeQuestionData={questionObject} btnDisabled={btnDisabled} validateData={validateData}/>
      )

      case "checkboxWithExpander":return(
        <CheckboxWithExpander className={singleOrMulti} formType={"pre-nyserda"} coursesList={coursesList} item={questionObject} completeQuestionData={questionObject} btnDisabled={btnDisabled} validateData={validateData}/>
      )
    }

  }
      
  return (
    <div className='card' style={{backgroundColor: 'white', top: '-1px'}}>
        <div className='formbg weact'>
        <span className='formheading' style={{color: 'black'}}><Translate>TRAINING PRE SURVEY INFORMATION</Translate></span><br /><br />
        <div className='single-elements-container weact'>
            { 
              Object.keys(formData).map((eachQuestion, i) => {
              if (i < 9) {
                  return (
                  <div>
                      {getContent(formData[eachQuestion], 'single-element')}
                  </div>
                )
              }
              })
            }
        </div>
        <div className='weact-question-container'>
            {
            Object.keys(formData).map((eachQuestion, i) => {
              if (i >= 9) {
                  return (
                  <div>
                    {getContent(formData[eachQuestion])}
                  </div>
                )
              }
              })
            }
            <div className='single-submit-button'>
              <FormButton text="Submit" color="success" handleSubmit={() => handleSubmit()} loading={loader}/>
            </div>
        </div>
        </div>
        <Modal isOpen={show} onClosed={() => handleCancel()} className="modal-dialog-centered">
          <ModalHeader><Translate>{modalName}</Translate></ModalHeader>
          <ModalBody>
             <div>
                <div className='d-flex flex-column justify-evenly align-items-center'>
                  <img src={FormSubmitted} style={{height: '100px', width: '100px'}}></img>
                  <p className='text-center fs-3'><Translate>Thank You!! Your response has been submitted</Translate></p>
                </div>
              </div>
          </ModalBody>
      </Modal>
    </div>
  )
}

export default Pretraining