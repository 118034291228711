import React from 'react'
import { Spinner } from 'reactstrap'
import '../Formbutton.css'
import { Translate } from 'react-auto-translate'
const FormButton = ({text, color, captchaVerified, handleSubmit, loading}) => {
  return (
      <button className={`button1 ${color}`} disabled={loading || captchaVerified} type='submit' onClick={() => handleSubmit()}> {loading ? <Spinner size={20}/> : 
      <Translate>
          {text}
      </Translate>  
      }</button>
  )
}

export default FormButton
