import React from 'react'
import { Translate } from 'react-auto-translate'
import '../Radio.css'
const GroupedRadio = (props) => {
    // console.log(props)
    const handleChange = (event) => {
        props.completeQuestionData['value'] = event.target.value
        props?.validateData()
    }

  return (
    <div className={`single-group-child ${props?.item?.dataSet['isError'] ? 'error-in-question' : ''}`}>
        <strong className='strongclass'><Translate>{props?.item?.dataSet.question}</Translate>{props?.item?.dataSet.isRequired ? <span className='mandatory'>*</span> : null}</strong>
        <div className='radiobuttons1'>
            <div className='radioheadings'>
                {
                    Object.keys(props?.item?.dataSet['options']).map((option,i)=>{
                        return(
                            <span className='radio-span'><Translate>{props?.item?.dataSet['options'][option]}</Translate></span>
                        )
                    })
                }
            </div>
            <div className='question-option'>
            {
                
                Object.keys(props?.item?.dataSet?.options).map((option)=>{
                    return(
                        <div className='radio-span'>
                            <input type="radio" className='form-radio' value={props.item.dataSet['options'][option]} name={props?.item?.dataSet?.groupName}
                            // onChange={(event) => props?.item?.setFormData({...props?.item?.formData, [props?.item?.formDataKey[props.item.choices[ch]]]: event.target.value})}
                            disabled={props?.btnDisabled}
                            onChange={(event) => handleChange(event)}
                            checked={props?.item?.value === props?.item?.dataSet?.options[option]}
                            // disabled={props?.btnDisabled}
                            ></input>
                        </div>
                    ) 
                })  
            }
            </div>
            
        </div>
        {props?.item?.dataSet['isError'] ? <p className='error-message-container mt-2'><Translate>{props?.item?.dataSet['errorMessage']}</Translate></p> : null}
   
    </div>
  )
}

export default GroupedRadio
