import React from 'react'

const PageNotFound = () => {
    return (
        <div style={{height: '100vh'}}>
            <div className='mt-5 d-flex justify-content-evenly align-items-center'>
                <div className='h-25 d-flex align-items-center flex-column'>
                    <h1 className='fw-bolder' style={{fontSize: '60px', color: 'white'}}>404</h1>
                    <h3 style={{color: 'white', fontSize: '25px'}}>Page Not Found!!</h3>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound