import React from 'react'
import { Translate } from 'react-auto-translate'
import '../Inputs.css'
const WeActParagraph = () => {
  return (
    <div>
        <h3 className='centertext weact'>
          <Translate>
            Welcome to WE ACT Green Institute!
        </Translate>
        </h3>
      <p className='para weact'><Translate>
          In 2023, WE ACT rebranded its workforce development program, launching WE ACT’s Green Institute. The mission remains the same, training and connecting local residents to good paying jobs in the green economy.  In addition to the 30-hour OSHA Certificate, Site Safety Training and Flagger/Scaffolding required for entry-level positions in the construction industry, along with Solar Photovoltaic Installation certification, WE ACT’s Green Institute also offers training for free 60-hour energy efficiency technical training to earn GPRO Fundamentals of Building Green Certification. And soon we'll be adding training for off-shore wind work.
        </Translate>
      <br /><br />

      <p className='para weact'>
        <Translate>WE ACT’s Green Institute is open to everyone aged 18-years and older, regardless of background. All applicants will undergo interviews, evaluations, and a screening/selection process.</Translate>
      </p>
      <p className='para weact'>
        <Translate>
        To learn more, <b>please fill out the form below.</b>
        </Translate>
      </p>
      </p>
      <span className='success weact'>
        <Translate>
          APPLICANT INFORMATION
        </Translate>
      </span><br /><br />
    </div>
  )
}

export default WeActParagraph