import React from 'react'
import {Routes,Route, useLocation, Router, Navigate } from 'react-router-dom';
import App from './App'
import Header from './Components/Header'
import Footer from './Components/Footer'
import Elevatorpitchform from './Forms/Elevatorpitchform';
import Pretraining from './Forms/Pretraining';
import Posttraining from './Forms/Posttraining';
import Occupationform from './Forms/Occupationform';
import Studentuploads from './Forms/Studentuploads';
import './App.css'
import StudentApplication from './Forms/studentApplication';
import ADNYApplication from './Forms/adnyForm';
import WeActStudentApplication from './Forms/WeActForms/studentApplications';
import WeActFooter from './Forms/WeActForms/WeActComponents/Footer';
import ReactGA from 'react-ga4';
import TestWeActStudentApplication from './Forms/WeActForms/testApplication';
import PageNotFound from './ErrorPages/404';

const App2 = () => {
  const {pathname} = useLocation();
  console.log("pathname: ", pathname);

  if (document.location.href.includes("/cea")) {
    document.location.replace('/application')
  } else if (document.location.href.includes("/nyserda-pre-survey")) {
    document.location.replace('/training-pre-survey')
  } else if (document.location.href.includes("/nyserda-post-survey")) {
    document.location.replace('/training-post-survey')
  } 

  ReactGA.initialize("G-JHZP14LZGM");
  return (
    <div>
      <Header/>
      <div className={`body ${(pathname === "/we-act-application" || pathname === "/test-applications") ? 'weact' : null}`}>
      <Routes>
            <Route path='/' element={<App />} />
            <Route path={'/application'} element={<StudentApplication/>} />
            {/* <Route path='/add_user' element={<RegisterOnATS/>} /> */}
            <Route path='/elevator' element={<Elevatorpitchform/>} />
            <Route path='/training-pre-survey' element={<Pretraining/>} />
            <Route path='/training-post-survey' element={<Posttraining/>} />
            <Route path='/adny-registration' element={<ADNYApplication/>} />
            <Route path='/we-act-application' element={<WeActStudentApplication/>} />
            <Route path='/test-applications' element={<TestWeActStudentApplication/>} />
            <Route path='/adny-satisfaction-survey' element={<Occupationform/>} />
            <Route path='/uploads' element={<Studentuploads/>} />
            <Route path='*' element={<PageNotFound/>} />
        </Routes>
      </div>

        {(pathname === "/" || pathname === "/we-act-application" || pathname === "/test-application") ? <WeActFooter/> : <WeActFooter/>}
    </div>
  )
}
export default App2