import React from 'react'
import { Translate } from 'react-auto-translate'

const RadioHavingOptionChildren = (props) => {

    const handleCheckValue = (event, selectedOption) => {
        if (event.target.checked) {
            let optionsList = props.completeQuestionData['dataSet']['options']
            optionsList.forEach((option) => {
                if (selectedOption.value === option.value) {
                    // If the selected radio option having only one children then set value
                    if ((option['childrens']).length === 1) {
                        props.completeQuestionData['value'] = option['childrens'][0]
                    } else if ((option['childrens']).length === 0) {
                        props.completeQuestionData['value'] = option['value']
                    } else {
                        props.completeQuestionData['value'] = ''
                    }
                    option.showChildren = true
                } else {
                    option.showChildren = false
                }
            })
            props.completeQuestionData['dataSet']['options'] = optionsList
        }
        props?.validateData()
    }

    const handleChange = (event, value) => {
        console.log("event.target.value: ", event.target.value, value);
        props.completeQuestionData['value'] = value
        props?.validateData()
    }

    const getQuestion = (question) => {
        if ((question).includes('Full Time')) {
            const splittedQuestion = (question).split('Full Time')
            return <p>{splittedQuestion[0]} <span style={{color: 'black', fontWeight: '600'}}><Translate>Full Time</Translate></span> <Translate>{splittedQuestion[1]}</Translate> {props?.item?.dataSet?.isRequired ? <span className='required'>*</span> : null}</p>
        } else if ((question).includes('Part Time')) {
            const splittedQuestion = (question).split('Part Time')
            return <p>{splittedQuestion[0]} <span style={{color: 'black', fontWeight: '600'}}><Translate>Part Time</Translate></span> <Translate>{splittedQuestion[1]}</Translate> {props?.item?.dataSet?.isRequired ? <span className='required'>*</span> : null}</p>    
        } else {
            return question
        }
    }

    return (
        <div className={`single-group-child ${props?.item['isError'] ? 'error-in-question' : ''}`}>
        <strong className='question-element'><Translate>{props?.item?.question}</Translate>{props?.item?.isRequired ? <span className='required'>*</span> : null}</strong>
        {(props?.item?.options).map((option, i) => {
            return (
                <div className='option-container d-flex' key={i + props?.item?.groupName}>
                    <div>
                        <div>
                            <input type="radio" id={(option['line1'])}
                            name={props?.item?.groupName}
                            //  defaultValue={props?.completeQuestionData?.isChildren ? `${option['line1']} ${option['line2']} ${option['line3']}` : option} 
                            onChange={(event) => handleCheckValue(event, option)}
                            checked={(option['showChildren']) || option.value === props?.completeQuestionData?.value}
                            disabled={props?.btnDisabled}
                            >
                            </input>
                            <span className={`${(option['showChildren']) ? 'text-bold' : null}`}><Translate>{option.value}</Translate></span>
                        </div>
                        <div>
                            {(option['showChildren']) && (option.childrens).map((childOption) => {  
                                return (
                                    <div className='option-container mx-5' key={i + childOption}>
                                        <input type="radio"
                                        name={childOption.groupName}
                                        // defaultValue={props?.completeQuestionData?.isChildren ? `${option['line1']} ${option['line2']} ${option['line3']}` : option} 
                                        onChange={(event) => handleChange(event, childOption)}
                                        checked={(props?.completeQuestionData?.value).includes(childOption)}
                                        disabled={props?.btnDisabled}
                                        >
                                        </input>
                                        <span><Translate>{getQuestion(childOption)}</Translate></span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )
        })}

        {props?.item['isError'] ? <p className='error-message-container'><Translate>{props?.item['errorMessage']}</Translate></p> : null}
    </div>
    )
} 

export default RadioHavingOptionChildren