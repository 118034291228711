import React from 'react'
import { ErrorMessage } from 'formik';
import '../Inputs.css'
import { EMAIL_REGEX } from '../utils/constants';
import { checkUserEmail, checkUserExists, getStudentApplicationByEmail } from '../Apis/studentApplication';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Translate } from 'react-auto-translate';
import { useRef } from 'react';

const Input = (props) => {
  // console.log(props)

  const [allowToUserDetails, setAllowToUserDetails] = useState(true)
  const [show, setShow] = useState(false)
  const [userAlreadyFilledFormData, setUserAlreadyFilledFormData] = useState({})

  const handleChange = (event, inputType) => {
    console.log("value length", (''+event.target.value).length, (/^\d+$/).test(event.target.value));
    // console.log(props?.formType)
    if (inputType === "numeric") {
      if ((/^\d+$/).test(event.target.value)) {
        if (props?.item?.value !== undefined && props?.item?.value !== null && (''+event.target.value).length > 0) {
          // If question is email then check email regex
          console.log("(EMAIL_REGEX).test(event.target.value): ", (EMAIL_REGEX).test(event.target.value), event.target.value, props?.item?.value);
          if (props?.item?.dataSet?.formDataKey === "email") {
            
            if ((EMAIL_REGEX).test(event.target.value)) {
              props.item.dataSet.isError = false
              props.item.dataSet.errorMessage = ''
              let checkingUser = toast.loading(<Translate>Checking if user exists...</Translate>, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
              })
              try {
                if(props.formType === "student" || props.formType === "elevatorPitch" || props.formType === "postNysedra" || props.formType === "preNysedra"){
                  checkUserEmail({email: event.target.value, type: props.formType}).then(res => {
                  console.log("checkUserExists: ", res);
                  if (res.status) {
                    props.item.userVerified = true
                    toast.dismiss(checkingUser)
                    toast.success(<Translate>User Exist</Translate>, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                    })
                    props?.setBtnDisabled(false)
    
                    if(props?.formType === "student" || props?.formType === "elevatorPitch" || props?.formType === "preNysedra"){
                        setShow(true)
                        setUserAlreadyFilledFormData(res.data[0])
                        // props?.setFormData({...props?.formData, fName: {...props?.formData['fName'], value: res.data[0]['firstname'] || '', dataSet: {...props?.formData['fName']['dataSet'], isError: res.data[0]['firstname'] ? false: true, errorMessage: res.data[0]['firstname'] ? "" : "Required"}}, lName: {...props?.formData['lName'], value: res.data[0]['lastname'] || '', dataSet: {...props?.formData['lName']['dataSet'], isError: res.data[0]['lastname'] ? false: true, errorMessage: res.data[0]['lastname'] ? "" : "Required"}}})
                        props?.setUserExistsData(res.data[0])
                    }
                    // else{
                    //   setShow(false)
                    // }
                    //// Check User Form Data Alfreafy Exists or Not
    
                    // getStudentApplicationByEmail(event.target.value).then(res => {
                    //   if (res !== null) {
                    //     if (res.data && res.data.length > 0) {
                    //       props?.prefillApplicationForm(res.data[0])
                    //       setShow(true)
                    //       setUserAlreadyFilledFormData(res.data[0])
                    //     }
                    //   }
                    // }).catch(error => {
                    //   console.log("error: ", error);
                    //   toast.error('Something went wrong')
                    // })
                  } else {
                    props.item.userVerified = false
                    toast.dismiss(checkingUser)
                    toast.error(<Translate>{res.message}</Translate>, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                    })
                    setShow(false)
                    setUserAlreadyFilledFormData({})
                    // let formData = props?.formData
                    // Object.keys(formData).forEach((eachQuestion) => {
                    //   if (formData[eachQuestion].type === "simpleCheckbox" || formData[eachQuestion].type === "checkboxWithOther") {
                    //     formData[eachQuestion]['value'] = []
                    //   } else {
                    //     if (formData[eachQuestion].type) {
                    //       formData[eachQuestion]['value'] = ''
                    //     }
                    //   }
                    // })
                    // console.log("formData formData: ", formData);
                    if (props?.formType === "student") {
                      props?.setFormData({...props?.formData, 'emailVerified' : false, 'phoneVerified' : false})
                    }
                    // const formdata = props?.formData
                    // formdata.forEach((item) => {
                    //   if (item.disable !== undefined) {
                    //     item.disable = true
                    //   }
                    // })
                    // props?.setFormData(formdata)
                    props?.setBtnDisabled(false)
                  }
                })
                } else {
                  try {
                    checkUserExists(event.target.value).then(res =>{
                      if(res!==null){
                        if(res.status){
                              props.item.studentMoodleId = (res.data.id).toString();
                              console.log(res.data.studentCourses)
                              props.item.name = res.data.fullname;
                              console.log(props.item.studentMoodleId)
                              props?.setBtnDisabled(false)
                              try {
                                    if (res.data.studentCourses) {
                                      props.item.enrolledCoursesByStudent = res.data.studentCourses;
                                    } else {
                                      props.item.enrolledCoursesByStudent = [];
                                  
                                    }
                                } catch (error) {
                                  props.item.enrolledCoursesByStudent = [];
                                }
                                console.log(props.item.enrolledCoursesByStudent)
                              props?.setCoursesList(res.data.studentCourses || [])
                              toast.dismiss(checkingUser)
                              toast.success(<Translate>User Exist</Translate>, {
                                position: "bottom-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                            })
                          }else {
                            toast.dismiss(checkingUser)
                            toast.error(<Translate>User does not exist</Translate>, {
                                position: "bottom-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                            })
                        }
    
                      }
                    
                    })
                  } catch (error) {
                    toast.dismiss(checkingUser)
                    toast.error(<Translate>Something Went Wrong</Translate>, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                    })
                  }
                }
            } catch (error) {
                toast.dismiss(checkingUser)
                toast.error(<Translate>Something Went Wrong</Translate>, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                })
                // props?.setBtnDisabled(true)
              }
            } else {
              props.item.dataSet.isError = true
              props.item.dataSet.errorMessage = 'Invalid Format'
              props.item.userVerified = false
              if (props?.item?.dataSet?.formDataKey === "email") {
                props?.setBtnDisabled(true)
                setUserAlreadyFilledFormData({})
                setShow(false)
              }
            }
          
          }
        } else {
          if (props?.item?.dataSet?.isRequired) {
            props.item.dataSet.isError = true
            props.item.dataSet.errorMessage = 'Required'
          }
          if (props?.item?.dataSet?.formDataKey === "email") {
            setUserAlreadyFilledFormData({})
            setShow(false)
            let formData = props?.formData
            Object.keys(formData).forEach((eachQuestion) => {
              if (formData[eachQuestion].type === "simpleCheckbox" || formData[eachQuestion].type === "checkboxWithOther") {
                formData[eachQuestion]['value'] = []
              } else {
                formData[eachQuestion]['value'] = ''
              }
            })
            props?.setFormData(formData)
            props?.setBtnDisabled(true)
          }
          props.item.userVerified = false
        }
        props.item.value = event.target.value
        props?.validateData()
      }
    } else {
      if (props?.formType === "adny") {
        props.item.value = event.target.value
        props?.validateData()
      } else {
        if (props?.item?.value !== undefined && props?.item?.value !== null && (''+event.target.value).length > 0) {
          // If question is email then check email regex
          console.log("(EMAIL_REGEX).test(event.target.value): ", (EMAIL_REGEX).test(event.target.value), event.target.value, props?.item?.value);
          if (props?.item?.dataSet?.formDataKey === "email") {
            
            if ((EMAIL_REGEX).test(event.target.value)) {
              props.item.dataSet.isError = false
              props.item.dataSet.errorMessage = ''
              let checkingUser = toast.loading(<Translate>{props.formType === "weact-form" ? '' : 'Checking if user exists...'}</Translate>, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
              })
              try {
                if(props.formType === "student" || props.formType === "elevatorPitch" ){
                  checkUserEmail({email: event.target.value, type: props.formType}).then(res => {
                  console.log("checkUserExists: ", res);
                  if (res.status) {
                    props.item.userVerified = true
                    toast.dismiss(checkingUser)
                    toast.success(<Translate>User Exist</Translate>, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                    })
                    props?.setBtnDisabled(false)
    
                    if(props?.formType === "student" || props?.formType === "elevatorPitch"){
                        setShow(true)
                        setUserAlreadyFilledFormData(res.data[0])
                        props?.setUserExistsData(res.data[0])
                    }
                    // else{
                    //   setShow(false)
                    // }
                    //// Check User Form Data Alfreafy Exists or Not
    
                    // getStudentApplicationByEmail(event.target.value).then(res => {
                    //   if (res !== null) {
                    //     if (res.data && res.data.length > 0) {
                    //       props?.prefillApplicationForm(res.data[0])
                    //       setShow(true)
                    //       setUserAlreadyFilledFormData(res.data[0])
                    //     }
                    //   }
                    // }).catch(error => {
                    //   console.log("error: ", error);
                    //   toast.error('Something went wrong')
                    // })
                  } else {
                    props.item.userVerified = false
                    toast.dismiss(checkingUser)
                    toast.error(<Translate>{res.message}</Translate>, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                    })
                    setShow(false)
                    setUserAlreadyFilledFormData({})
                    // let formData = props?.formData
                    // Object.keys(formData).forEach((eachQuestion) => {
                    //   if (formData[eachQuestion].type === "simpleCheckbox" || formData[eachQuestion].type === "checkboxWithOther") {
                    //     formData[eachQuestion]['value'] = []
                    //   } else {
                    //     if (formData[eachQuestion].type) {
                    //       formData[eachQuestion]['value'] = ''
                    //     }
                    //   }
                    // })
                    // console.log("formData formData: ", formData);
                    if (props?.formType === "student") {
                      props?.setFormData({...props?.formData, 'emailVerified' : false, 'phoneVerified' : false})
                    }
                    // const formdata = props?.formData
                    // formdata.forEach((item) => {
                    //   if (item.disable !== undefined) {
                    //     item.disable = true
                    //   }
                    // })
                    // props?.setFormData(formdata)
                    props?.setBtnDisabled(false)
                  }
                })
                } else {
                  toast.dismiss(checkingUser)
                  if (props.formType !== "weact-form") {
                    try {
                      checkUserExists(event.target.value).then(res =>{
                        if(res!==null){
                          if(res.status){
                                props.item.studentMoodleId = (res.data.id).toString();
                                console.log(res.data.studentCourses)
                                props.item.name = res.data.fullname;
                                console.log(props.item.studentMoodleId)
                                props?.setBtnDisabled(false)
                                try {
                                      if (res.data.studentCourses) {
                                        props.item.enrolledCoursesByStudent = res.data.studentCourses;
                                      } else {
                                        props.item.enrolledCoursesByStudent = [];
                                    
                                      }
                                  } catch (error) {
                                    props.item.enrolledCoursesByStudent = [];
                                  }
                                  // console.log(props.item.enrolledCoursesByStudent)
                                  if (props?.formType === "postNysedra") {
                                    props?.setCoursesList([{
                                      fullname: "GPRO Fundamentals of Building Green",
                                      shortname: "GPRO-FBG",
                                    }])
                                  } else {
                                    props?.setCoursesList(res.data.studentCourses || [])
                                  }

                                  // Prefill first Name and last name in case of pre survey and post survey form
                                  props?.setFormData({...props?.formData, fName: {...props?.formData['fName'], value: res.data['firstname'] || '', dataSet: {...props?.formData['fName']['dataSet'], isError: res.data['firstname'] ? false: true, errorMessage: res.data['firstname'] ? "" : "Required"}}, lName: {...props?.formData['lName'], value: res.data['lastname'] || '', dataSet: {...props?.formData['lName']['dataSet'], isError: res.data['lastname'] ? false: true, errorMessage: res.data['lastname'] ? "" : "Required"}}})

                                toast.dismiss(checkingUser)
                                toast.success(<Translate>User Exist</Translate>, {
                                  position: "bottom-right",
                                  autoClose: 3000,
                                  hideProgressBar: false,
                              })
                            }else {
                              toast.dismiss(checkingUser)
                              toast.error(<Translate>User does not exist</Translate>, {
                                  position: "bottom-right",
                                  autoClose: 3000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                              })
                          }
      
                        }
                      
                      })
                    } catch (error) {
                      toast.dismiss(checkingUser)
                      toast.error(<Translate>Something Went Wrong</Translate>, {
                          position: "bottom-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                      })
                    }
                  } else {
                    props?.setBtnDisabled(false)
                  }
                }
            } catch (error) {
                toast.dismiss(checkingUser)
                toast.error(<Translate>Something Went Wrong</Translate>, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                })
                // props?.setBtnDisabled(true)
              }
            } else {
              props.item.dataSet.isError = true
              props.item.dataSet.errorMessage = 'Invalid Format'
              props.item.userVerified = false
              if (props?.item?.dataSet?.formDataKey === "email") {
                props?.setBtnDisabled(true)
                setUserAlreadyFilledFormData({})
                setShow(false)
              }
            }
          
          }
        } else {
          if (props?.item?.dataSet?.isRequired) {
            props.item.dataSet.isError = true
            props.item.dataSet.errorMessage = 'Required'
          }
          if (props?.item?.dataSet?.formDataKey === "email") {
            setUserAlreadyFilledFormData({})
            setShow(false)
            let formData = props?.formData
            Object.keys(formData).forEach((eachQuestion) => {
              if (formData[eachQuestion].type === "simpleCheckbox" || formData[eachQuestion].type === "checkboxWithOther") {
                formData[eachQuestion]['value'] = []
              } else {
                formData[eachQuestion]['value'] = ''
              }
            })
            props?.setFormData(formData)
            props?.setBtnDisabled(true)
          }
          props.item.userVerified = false
        }
        props.item.value = event.target.value
        props?.validateData()
      }
    }
  }

  const handelBlur = (value) => {
    props.item.value = value
    props?.validateData()
  }

  const handleCommunicationDetails = (checkValue) => {
    setAllowToUserDetails(checkValue)
    if (props?.item?.dataSet?.formDataKey === "email") {
      props.item.emailCommunication = checkValue
    } else {
      props.item.smsCommunication = checkValue
    }
  }

  // Prefill the data
  const handlePrefillForm = () => {
    props?.prefillApplicationForm(userAlreadyFilledFormData)
    setShow(false)
  }

  // Fill Fresh Form
  const handleFillFreshForm = () => {
    if (props?.formType === "student") {
      props?.setFormData({...props?.formData, 'emailVerified' : userAlreadyFilledFormData['attributes']['emailVerified'], 'phoneVerified' : userAlreadyFilledFormData['attributes']['phoneVerified']})
    }
    setShow(false)
  }


  return (
    <div className={`single-group-child ${props?.className} ${props?.item?.isVisible !== undefined && !props?.item?.isVisible ? 'hide-question' : null} ${props?.item?.dataSet['isError'] ? 'error-in-question' : ''}`}>
      <strong className='strongclass'><Translate>{props?.item?.dataSet?.question}</Translate>{props?.item?.dataSet?.isRequired ? <span className='mandatory'>*</span> : null}</strong>
      <div className='ui form'>
        <div className='field'>
          <input type={props?.item?.inputType} className='textcomponent'
            name={props?.item?.groupName}
            // value={props.item.value}
            defaultValue={props.item.value}
            // placeholder={props?.item.placeholder}
            disabled={props?.item?.dataSet?.formDataKey !== "email" ? props?.btnDisabled : false}
            // onChange={(event) => {handleChange(event, props?.item?.inputType);}}
            onBlur={(event) => handleChange(event, props?.item?.inputType)}
            >
            </input>
        </div>
        <div>
            <p>
              {props?.item?.notifyMe && props?.item?.dataSet?.formDataKey !== "email" ? 
                <input className='mx-1 my-1' type={'checkbox'} checked={props?.item?.dataSet?.formDataKey === "email" ? props?.item?.emailCommunication : props?.item?.smsCommunication} value="" onChange={(event) => handleCommunicationDetails(event.target.checked)}></input>
                : null
              }
              {props?.item?.notifyMe ? props?.item?.dataSet?.formDataKey === "email" && props?.formType === "student" ? "This is the email you will use to register and log on in the future" : 
              props?.item?.dataSet?.formDataKey === "phoneNumber" ?
              <Translate>Send me SMS notifications about training, certifications, career services and/or job opportunities.</Translate> : '' : ''
              }
              </p>
        </div>
     </div> 
     {props?.item?.dataSet['isError'] ? <p className='error-message-container mt-2'>{<Translate>{props?.item?.dataSet['errorMessage']}</Translate>}</p> : null}

     
     <Modal isOpen={show} onClosed={() => setShow(false)} className="modal-dialog-centered">
          <ModalHeader><Translate>Confirmation</Translate></ModalHeader>
          <ModalBody>
            <p className='text-justify'><Translate>It seems you have filled out this application before. Would you like to prefill this form with some of your previous registration details?</Translate></p>
            <div className='d-flex float-end my-2'>
              <button color='primary' className='button-primary' onClick={() => handlePrefillForm()}><Translate>Yes, Prefill My Form</Translate></button>
              <button className='mx-2 button-secondary' onClick={() => handleFillFreshForm()}><Translate>No, I'll Fill It Myself</Translate></button>
            </div>
          </ModalBody>
      </Modal>
      {/* <ToastContainer>
        
      </ToastContainer> */}
    </div>
  )
}

export default Input;
















// import React from 'react'
// import '../Inputs.css'
// const Input = ({ph}) => {
//   return (
//     <div className='ui form'>
//         <div className="field">
//             <input type="text" className='textcomponent' placeholder={ph}/>
//         </div>
//     </div>
//   )
// }

// export default Input;
