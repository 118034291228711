import React from 'react'
import { Translate } from 'react-auto-translate';
import '../Checkbox.css'
const Checkbox = (props) => {
  const handlechange=(e, option)=>{
    const {value ,checked } = e.target;
    let newarray = props?.completeQuestionData['value']
    if(checked){
      newarray.push(value)
      if (option === "None of the above" || option === "None of the above" || option === "I prefer not to say") {
        props.completeQuestionData['value'] = [option]
      } else {
        const valueArray = props.completeQuestionData['value']
        const filteredData = valueArray.filter((selectedOption) => ((selectedOption !== "None of the above" && selectedOption !== "I prefer not to say")))
        props.completeQuestionData['value'] = filteredData
      }
    }
    else{
      props.completeQuestionData['value'] = newarray.filter((e) => e !== value)
      // props?.item?.setFormData({...props?.item?.formData, [props?.item?.formDataKey]:newarray.filter((e)=>e!==value)})
    }
    props?.validateData()
  }
  // console.log(props)
  return (
    <div className={`single-group-child ${props?.item['isError'] ? 'error-in-question' : ''}`}>
        <strong className='strongclass'><Translate>{props?.item?.question}</Translate>{props?.item?.isRequired ? <span className='mandatory'>*</span> : null}</strong>
        
        {props?.formType === "adny" && props?.completeQuestionData?.addData ? <div>
          <p className='mt-2 fs-5'><Translate>All courses will be held on Zoom. Participants will need a Zoom Account and computer/tablet to receive educational materials.</Translate></p>
          <p><strong className='text-decoration-underline strongclass'><Translate>May Course: Heating & Domestic Hot Water Systems</Translate></strong></p>
          <p className='mb-0 fs-5'><Translate>~ Tuesday, May 3rd: 9AM - 5PM</Translate></p>
          <p className='mb-0 fs-5'><Translate>~ Tuesday, May 10th: 9AM - 5PM</Translate></p>
          <p className='mb-2 fs-5'><Translate>~ Tuesday, May 17th: 9AM - 5PM</Translate></p>
        </div> : null}
        {(props?.item?.options).map((option, i) => {
                return (
                    <div className='checkboxlabel' key={i}>
                        <input type="checkbox" id={(option)}
                         name={props?.item?.groupName}
                         defaultValue={option} 
                         disabled={props?.btnDisabled}
                         checked={(props?.completeQuestionData?.value).includes(option)}
                         onChange={(event) => handlechange(event, option)}
                         >
                         </input>
                        <span><Translate>{option}</Translate></span>
                    </div>
                )
            })}

          {props?.item['isError'] ? <p className='error-message-container'><Translate>{props?.item['errorMessage']}</Translate></p> : null}
      </div>
  )
}

export default Checkbox
