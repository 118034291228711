import React from 'react'
import { Translate } from 'react-auto-translate'
const MyButton = ({text}) => {
  return (
    <button className="button">
        <Translate>
        {text}
        </Translate>
        </button>
  )
}

export default MyButton
