import { API_DOMAIN, STRAPI_DOMAIN } from "../utils/constants"

export const getStudentApplicationByEmail = (emailString) => {

    const config = {
        method: 'GET',
        headers: {
            Authorization:  `Bearer ae5931560f8190ad8506dee91e8bc50799440b774defa1b44ff639e999c5335ff1beb906014862bbc2bfec530baf01917a83efac33384daa6d0a6a2646e5a65e7b2b3e4e3da9b582dc56731a710f1bd8712396b2fb22dbde428ed2a588e6bd927f55b6ff8e92a9f9cb1f0762cc1a7eecce227bf72f8749e861dac470320d9170`,
            'Content-Type': 'application/json'
        }
    }

    return fetch(`${STRAPI_DOMAIN}/api/students?populate=%2A&sort[0]=id%3Adesc&filters[$and][0][email][$eq]=${emailString}&pagination%5BwithCount%5D=true&pagination%5BpageSize%5D=1500&_limit=-1`, config).then(res => {
        if (res.status === 200) {
            return res.json()
        } else {
            return null
        }
    }).catch(() => {
        return null
    })
}


export const getStates = async () => {

    const config = {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ae5931560f8190ad8506dee91e8bc50799440b774defa1b44ff639e999c5335ff1beb906014862bbc2bfec530baf01917a83efac33384daa6d0a6a2646e5a65e7b2b3e4e3da9b582dc56731a710f1bd8712396b2fb22dbde428ed2a588e6bd927f55b6ff8e92a9f9cb1f0762cc1a7eecce227bf72f8749e861dac470320d9170',
            'Content-Type': 'application/json'
        }
    }

    return fetch(`${STRAPI_DOMAIN}/api/state-lists?pagination%5BwithCount%5D=true&pagination%5BpageSize%5D=50`, config).then(res => {
        if(res.status == 200){
            return res.json()
        }else{
            return null
        }
    }).catch(error => {
        return error
    })
}

export const checkUserEmail = async (payload) => {

    // const obj = {...payload, type: 'elevatorPitch'}
    const config = {
        method: 'POST',
        headers: {
            Authorization: 'ae5931560f8190ad8506dee91e8bc50799440b774defa1b44ff639e999c5335ff1beb906014862bbc2bfec530baf01917a83efac33384daa6d0a6a2646e5a65e7b2b3e4e3da9b582dc56731a710f1bd8712396b2fb22dbde428ed2a588e6bd927f55b6ff8e92a9f9cb1f0762cc1a7eecce227bf72f8749e861dac470320d9170',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    }

    return fetch(`${API_DOMAIN}/api_v1/student/checkStudentExists`, config).then(res => {
        return res.json()
    }).catch(() => {
        return null
    })
}

export const checkUserExists = async (email) => {

    const config = {
        method: 'GET',
        headers: {
            Authorization: 'ae5931560f8190ad8506dee91e8bc50799440b774defa1b44ff639e999c5335ff1beb906014862bbc2bfec530baf01917a83efac33384daa6d0a6a2646e5a65e7b2b3e4e3da9b582dc56731a710f1bd8712396b2fb22dbde428ed2a588e6bd927f55b6ff8e92a9f9cb1f0762cc1a7eecce227bf72f8749e861dac470320d9170',
            'Content-Type': 'application/json'
        }
    }

    return fetch(`${API_DOMAIN}/api_v1/moodle/checkStudentsRegistered?email=${email}`, config).then(res => {
        return res.json()
    }).catch(() => {
        return null
    })
}

export const getApplicationsVersions = async (type) => {

    let value = ''
    switch (type) {
        case "studentapplication": value = "CEA_Application"; break
        case "elevatorpitch": value = "Elevator_Pitch"; break
    }
    const config = {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ae5931560f8190ad8506dee91e8bc50799440b774defa1b44ff639e999c5335ff1beb906014862bbc2bfec530baf01917a83efac33384daa6d0a6a2646e5a65e7b2b3e4e3da9b582dc56731a710f1bd8712396b2fb22dbde428ed2a588e6bd927f55b6ff8e92a9f9cb1f0762cc1a7eecce227bf72f8749e861dac470320d9170',
            'Content-Type': 'application/json'
        }
    }

    return fetch(`${STRAPI_DOMAIN}/api/cea-app-vers?filters[$and][0][formName][$eq]=${value}&filters[$and][0][isDefault][$eq]=1`, config).then(res => {
        if (res.status === 200) {
            return res.json()
        } else {
            return null
        }
    }).catch(() => {
        return null
    })
}


export const addStudentsApplication = async (obj) => {

    var data = JSON.stringify(obj);

    const config = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ae5931560f8190ad8506dee91e8bc50799440b774defa1b44ff639e999c5335ff1beb906014862bbc2bfec530baf01917a83efac33384daa6d0a6a2646e5a65e7b2b3e4e3da9b582dc56731a710f1bd8712396b2fb22dbde428ed2a588e6bd927f55b6ff8e92a9f9cb1f0762cc1a7eecce227bf72f8749e861dac470320d9170',
            'Content-Type': 'application/json'
        },
        body: data
    }
    return fetch(`${STRAPI_DOMAIN}/api/students`, config).then(res => {
        if(res.status == 200){
            return res.json()
        }else{
            return null
        }
    }).catch(error => {
        return null
    })
}

export const verifyMyEmail = async (payload) => {

    const config = {
        method: 'POST',
        headers: {
            Authorization: 'ae5931560f8190ad8506dee91e8bc50799440b774defa1b44ff639e999c5335ff1beb906014862bbc2bfec530baf01917a83efac33384daa6d0a6a2646e5a65e7b2b3e4e3da9b582dc56731a710f1bd8712396b2fb22dbde428ed2a588e6bd927f55b6ff8e92a9f9cb1f0762cc1a7eecce227bf72f8749e861dac470320d9170',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    }

    return fetch(`${API_DOMAIN}/api_v1/student/otpVerify`, config).then(res => {
        return res.json()
    }).catch(() => {
        return null
    })
}

export const checkEmailVerify = async (email) => {

    const config = {
        method: 'GET',
        headers: {
            Authorization: 'ae5931560f8190ad8506dee91e8bc50799440b774defa1b44ff639e999c5335ff1beb906014862bbc2bfec530baf01917a83efac33384daa6d0a6a2646e5a65e7b2b3e4e3da9b582dc56731a710f1bd8712396b2fb22dbde428ed2a588e6bd927f55b6ff8e92a9f9cb1f0762cc1a7eecce227bf72f8749e861dac470320d9170',
            'Content-Type': 'application/json'
        }
        // body: JSON.stringify(payload)
    }

    return fetch(`${API_DOMAIN}/api_v1/student/checkStudentEmailVerified?email=${email}`, config).then(res => {
        return res.json()
    }).catch(() => {
        return null
    })
}


// export const AddStudentOnATS = async (obj) => {

//     const data = JSON.stringify(obj)

//     const config = {
//         method: 'POST',
//         headers: {
//             Authorization: 'ae5931560f8190ad8506dee91e8bc50799440b774defa1b44ff639e999c5335ff1beb906014862bbc2bfec530baf01917a83efac33384daa6d0a6a2646e5a65e7b2b3e4e3da9b582dc56731a710f1bd8712396b2fb22dbde428ed2a588e6bd927f55b6ff8e92a9f9cb1f0762cc1a7eecce227bf72f8749e861dac470320d9170',
//             'Content-Type': 'application/json'
//         },
//         body: data
//     }

//     return fetch(`${API_DOMAIN}/api_v1/user/addUsers`, config).then(res => {
//         if (res.status === 200) {
//             return res.json()
//         } else {
//             return null
//         }
//     }).catch(error => {
//         return error
//     })
// }

