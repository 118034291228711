import React, { useEffect, useState } from 'react'
import FormButton from '../Components/FormButton'
import '../Inputs.css'
import '../Radio.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormSubmitted from './../assets/submitted.png'
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Input from '../Inputs/Input'
import moment from 'moment/moment'
import { UplaodStudentFile } from '../Apis/upload'
import Upload from '../Components/Upload/upload'
import { Translate } from 'react-auto-translate'

const Studentuploads = () => {
  const [loader, setLoader] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [show, setShow] = useState(false)
  const [modalName, setModalName] = useState('')
  const categories = [{label: 'Cover Letter', value: 'coverLetter'}, {label: 'Resume', value: 'resume'}, {label: 'Project Submission', value: 'projectSubmission'}, {label: 'Elevator Pitch', value: 'elevatorPitch'}, {label: 'Participation Agreement', value: 'participationsAgreement'}, {label: 'MP4 Upload', value: 'video'}, {label: 'Testimonial', value: 'Testimonial'}, {label: 'ID Cards/Certificate', value: 'ID Cards/Certificate'}];

  useEffect(() => {
    document.title = "Student Uploads"
  }, [])

  const [formData, setFormData] = useState(
  {
      email: {value: '',studentMoodleId:null,enrolledCoursesByStudent:{},name:'',extra:{},userdata:true, dataSet: {question: "Email", isRequired: true, formDataKey: 'email', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', isEmail: true, userVerified: false, notifyMe: true, emailCommunication: true,placeholder:'Please enter a valid email address, as registered on moodle'}, 

      category: {value:'',dataSet:{question:"Category",isRequired: true, groupName: 'category',formDataKey: 'category', isError: false, errorMessage: ''}, type: 'dropdown', isEmail: true, userVerified: false,placeholder:'Select Category'},
       
      course:{value:'',dataSet:{question:"Course",isRequired:false,groupName:'course',formDataKey:'course',isError:false,errorMessage:''},type:'dropdown2',placeholder:"Select Category",disable:true},

      uploadDescription: {value: '', dataSet: {question: "File Name", isRequired: false, formDataKey: 'uploadDescription', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true,placeholder:'File Name'}, 

      resume: {value: '',url:'', dataSet: {question: "Upload Document", isRequired: true, formDataKey: 'resume', isError: false, errorMessage: '', allowedFileType: ['docx', 'pdf', 'doc','xlsx','mp4', 'webm', 'ogv', 'mov']}, type: 'upload', inputType: 'text', note: 'this is optional', disable: true},

      }
  )
  
  const [IsLoading, setIsLoading] = useState(false)
  const validateData = () => {
  let functionReturn = true
  let validateFormData = formData
  
  // console.log("email: ", email, formData['email']['dataSet']['isRequired'] && (formData['email']['dataSet']['formDataKey'] === "email"), validateFormData);

  Object.keys(validateFormData).forEach(eachQuestion => {
    // Checking Required ------------------------------------------------------------------------------------------------------------------
    console.log(validateFormData[eachQuestion]['dataSet'].isRequired)
      if(validateFormData[eachQuestion]['dataSet'].isRequired && (validateFormData[eachQuestion]['type']) ==='groupedradio'){
        const l=[]
        Object.keys(validateFormData[eachQuestion]['dataSet']['choices']).map((i)=>{
          if(validateFormData[eachQuestion]['dataSet']['choices'][i]!=''){
            l.push(validateFormData[eachQuestion]['dataSet']['choices'][i])
          }
        })
        if(l.length != Object.keys(validateFormData[eachQuestion]['dataSet']['choices']).length){
          validateFormData[eachQuestion]['dataSet']['isError'] = true
          validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
          functionReturn = false
        }
        else{
          validateFormData[eachQuestion]['dataSet']['isError'] = false
          validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
        }
      }
      
      else if(validateFormData[eachQuestion]['dataSet']['isRequired'] && (validateFormData[eachQuestion]['value']) <=0){
        
        validateFormData[eachQuestion]['dataSet']['isError'] = true
        validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
        functionReturn = false
      }
      else {
        validateFormData[eachQuestion]['dataSet']['isError'] = false
        validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
      }
    // Checking Required ------------------------------------------------------------------------------------------------------------------

  })
  setIsLoading(true)
  setTimeout(() => {
    setIsLoading(false)
  }, 2000)
  setFormData(validateFormData)
  return functionReturn
  }

  const handleReloadForm = () => {
    setModalName('Thank You')
    setTimeout(() => {
      window.location.reload()
    }, 3000)
  }
  const handleCancel = () => {
    setShow(false)
  }
    
  const handleSubmit = () => {

  if (validateData()) {
    setLoader(true);
    let selectedCourseObj = []
    let selectedCourseObjFinal = {}
    if ((formData.course.value).length > 0) {
        let selectedCourseName = formData.course.value;
        selectedCourseObj = formData.email.enrolledCoursesByStudent.filter((item) => item.fullname === selectedCourseName)
        console.log("selectedCourseObj: ", selectedCourseObj);
        if (selectedCourseObj.length > 0) {
            selectedCourseObjFinal['courseId'] = selectedCourseObj[0].id 
            selectedCourseObjFinal['courseShortname'] = selectedCourseObj[0].shortname 
            selectedCourseObjFinal['courseFullname'] = selectedCourseObj[0].fullname 
        }
    }
    let dateString = new Date();
    dateString = moment(dateString).format('YYYY-MM-DD');
    UplaodStudentFile({data: {
      name:formData['email']['name'],
      email: formData['email']['value'],
      category:formData['category']['value'],
      date: moment().format('YYYY-MM-DD'),
      course: formData['email']['enrolledCoursesByStudent'],
      description: formData['uploadDescription']['value'],
      url:formData["resume"]['url'],
      extra:selectedCourseObjFinal,
      resume: formData['resume']['value'],
      studentMoodleId:formData['email']['studentMoodleId'],
  }}).then(res => {
    if (res !== null) {
      setShow(true)
      setModalName("Thank You")
      setLoader(false)
      toast.success(<Translate>Application Submitted Successfully</Translate>)
      handleReloadForm()
    }
    }).catch(error => {
      console.log("error: ", error);
      setLoader(false)
      toast.error(<Translate>Something went wrong</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
    })

  }

  console.log(formData)
  }

  const getContent = (questionObject) => {
    // console.log("questionObject: ", questionObject.type);

    switch(questionObject.type) {
      
      case "simpleInput":  return(
        
        <Input item={questionObject} btnDisabled={btnDisabled} formType="studentuploads" setBtnDisabled={setBtnDisabled}  completeQuestionData={questionObject} formData={formData} validateData={validateData} setFormData={setFormData}/>
      )
    
      case "upload": return (
        <Upload file={formData.resume.value} setLoader={setLoader} item={questionObject} email={formData.email.value} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
        // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
      )
      case "dropdown": return (
        <div className={`single-group-child ${formData.category.dataSet['isError'] ? 'error-in-question' : ''}`}>
                <strong className="strongclass"><Translate>{formData.category.dataSet.question}</Translate>{formData.category.dataSet.isRequired ? <span className="mandatory">*</span>:null}</strong>
                <select className="form-control" placeholder={formData.category.placeholder} defaultValue={"Select Category"} name={formData.category.groupName} id="state" btnDisabled={btnDisabled} onChange={(event)=>formData.category['value']=event.target.value}>
                  <option value={"Select Category"} key={'22'} disabled selected><Translate>{"Select Category"}</Translate></option>
                    {
                    categories.map((val, i) => {

                        return(
                          <option key={i} value={val.value}><Translate>{val.label}</Translate></option>
                        )
                      })
                    }
                </select>
            
            {formData.category.dataSet['isError']  ? <p className='error-message-container'>{formData.category.dataSet['errorMessage']}</p> : null}
        </div>
        )

        case "dropdown2":return(
          <>
          {Object.keys(formData.email.enrolledCoursesByStudent).length > 0 ?
          <div className={`single-group-child ${formData.course.dataSet['isError'] ? 'error-in-question' : ''}`}>
                <strong className="strongclass">{formData.course.dataSet.question}{formData.course.dataSet.isRequired ? <span className="mandatory">*</span>:null}</strong>
                <select className="form-control" placeholder={formData.course.placeholder} name={formData.course.groupName} id="state" btnDisabled={btnDisabled} onChange={(event)=>formData.course['value']=event.target.value}>
                <option value="Select Course" disabled selected><Translate>Select Course</Translate></option>
                    {
                    Object.keys(formData.email.enrolledCoursesByStudent).map((val, i) => {
                      // console.log(formData.email.enrolledCoursesByStudent[val])
                        return(
                          <option key={i} value={formData.email.enrolledCoursesByStudent[val].fullname}><Translate>{formData.email.enrolledCoursesByStudent[val].fullname}</Translate></option>
                        )
                      })
                    }
                </select>
            {formData.category.dataSet['isError']  ? <p className='error-message-container'>{formData.category.dataSet['errorMessage']}</p> : null}
        </div>:null}
        </>
        )
    
    }

  }
  
  return (
    <div className='card'>
      <div className='formbg'>
      <span className='formheading'><Translate>UPLOAD FILE</Translate></span><br /><br />
        <div style={{display: 'grid', gridColumnGap: '20px'}}>
            { 
              Object.keys(formData).map((eachQuestion, i) => {
              if (i <= 5) {
                  return (
                  <div>
                      {getContent(formData[eachQuestion])}
                  </div>
                )
              }
              }) 
            }
        </div>
        {/* {
        Object.keys(formData).map((eachQuestion, i) => {
          if (i >= 4) {
              return (
              <div>
                {getContent(formData[eachQuestion])}
              </div>
            )
          }
          }) 
        } */}
            <div className='single-submit-button'>
              <FormButton text="Submit" handleSubmit={() => handleSubmit()} loading={loader}/>
            </div>
      </div>
      <Modal isOpen={show} onClosed={() => handleCancel()} className="modal-dialog-centered">
          <ModalHeader><Translate>{modalName}</Translate></ModalHeader>
          <ModalBody>
             <div>
                <div className='d-flex flex-column justify-evenly align-items-center'>
                  <img src={FormSubmitted} style={{height: '100px', width: '100px'}}></img>
                  <p className='text-center fs-3'><Translate>Thank You!! Your response has been submitted</Translate></p>
                </div>
              </div>
          </ModalBody>
      </Modal>
    </div>
  )

}

export default Studentuploads