import React from 'react'
import './../../../Footer.css'
import WeActLogo from '../../../weact_logo.png'
const WeActFooter = () => {
  return (
    <div className='footer weact'>
      <img src={WeActLogo} alt="" />
      <div className='footercontent weact'>
        <p></p>
        <h5>For More information, Contact:</h5>
        <br />
        <br />
        <b>Charles Callaway</b><br />
        <i> Director of Workforce Development <u>charles@weact.org</u></i>
        <p className='copyright weact'>Copyright © 2023 WE ACT. All rights reserved</p>
      </div>
    </div>
  )
}

export default WeActFooter
// For class information, contact:

// Charles Calloway
// Director of Workforce Development
// charles@weact.org