import { API_DOMAIN,STRAPI_DOMAIN } from "../utils/constants";

export const checkUserEmail = async (email) => {

    const config = {
        method: 'GET',
        headers: {
            Authorization: 'ae5931560f8190ad8506dee91e8bc50799440b774defa1b44ff639e999c5335ff1beb906014862bbc2bfec530baf01917a83efac33384daa6d0a6a2646e5a65e7b2b3e4e3da9b582dc56731a710f1bd8712396b2fb22dbde428ed2a588e6bd927f55b6ff8e92a9f9cb1f0762cc1a7eecce227bf72f8749e861dac470320d9170',
            'Content-Type': 'application/json'
        }
    }

    return fetch(`${API_DOMAIN}/api_v1/moodle/checkStudentsRegistered?email=${email}`, config).then(res => {
        return res.json()
    }).catch(() => {
        return null
    })
}
export const uploadResume = async (resume, email) => {
    // console.log("resume: ", resume);
    var percentCompleted;
    const config = {
        onUploadProgress: function(progressEvent) {
            percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            console.log(percentCompleted)
            // toast.update(uploadingToast, {render: `${percentCompleted}% Uploaded`, type: "progress", isLoading: true})
        },
        method: 'POST',
        headers: {
            authorization: 'ae5931560f8190ad8506dee91e8bc50799440b774defa1b44ff639e999c5335ff1beb906014862bbc2bfec530baf01917a83efac33384daa6d0a6a2646e5a65e7b2b3e4e3da9b582dc56731a710f1bd8712396b2fb22dbde428ed2a588e6bd927f55b6ff8e92a9f9cb1f0762cc1a7eecce227bf72f8749e861dac470320d9170',
        },
        body: resume
    }

    return fetch(`${API_DOMAIN}/api_v1/s3/upload?email=${email}`, config).then(res => {
        return [res.json(),percentCompleted];
    }).catch(error => {
        return error
    })
}


export const UplaodStudentFile = (obj) => {

    var data = JSON.stringify(obj);

    const config = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ae5931560f8190ad8506dee91e8bc50799440b774defa1b44ff639e999c5335ff1beb906014862bbc2bfec530baf01917a83efac33384daa6d0a6a2646e5a65e7b2b3e4e3da9b582dc56731a710f1bd8712396b2fb22dbde428ed2a588e6bd927f55b6ff8e92a9f9cb1f0762cc1a7eecce227bf72f8749e861dac470320d9170',
            'Content-Type': 'application/json'
        },
        body: data
    }

    return fetch(`${STRAPI_DOMAIN}/api/student-files`, config).then(res => {
        if(res.status == 200){
            return res.json()
        }else{
            return null
        }
    }).catch(error => {
        return error
    })
}


export const checkUserExists = async (email) => {

    const config = {
        method: 'POST',
        headers: {
            Authorization: 'ae5931560f8190ad8506dee91e8bc50799440b774defa1b44ff639e999c5335ff1beb906014862bbc2bfec530baf01917a83efac33384daa6d0a6a2646e5a65e7b2b3e4e3da9b582dc56731a710f1bd8712396b2fb22dbde428ed2a588e6bd927f55b6ff8e92a9f9cb1f0762cc1a7eecce227bf72f8749e861dac470320d9170',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({email: email})
    }

    return fetch(`${API_DOMAIN}/api_v1/student/checkStudentExists`, config).then(res => {
        return res.json()
    }).catch(() => {
        return null
    })
}