import React, { useState } from "react";
import { uploadResume } from "../../Apis/upload";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Badge } from "reactstrap";
import Info from "feather-icons-react/build/IconComponents/Info";
import axios from "axios";
import { Translate } from "react-auto-translate";
// import {API_DOMAIN} from '.../utils/constants'

const Upload = (props) => {

    const [btnDisable, setBtnDisable] = useState(false);

    let getAllowedFileTypeString  = '';
    
    getAllowedFileTypeString = (props?.item?.dataSet?.allowedFileType).map((fileType) => {
        return fileType
    }).join(' | ')

    const handleChange = (event) => {
        props.completeQuestionData['value'] = event.target.value
        props.completeQuestionData['file'] = true;
        props?.validateData()
    }
    
    const handleUploadResume = (files) => {
         console.log("files: ", files);
         const formData = new FormData();
         setBtnDisable(true);
         formData.append('files', files)
         let checkDocx = files.name.split(".");
         let type = (files.type).split('/');
        //  console.log(type[type.length-1])
        //  console.log(checkDocx[checkDocx.length - 1])
         console.log("checkDocx: ", (props?.item?.dataSet?.allowedFileType).includes(type[type.length-1]) 
         || (props?.item?.dataSet?.allowedFileType).includes(checkDocx[checkDocx.length - 1])
         );
         if((props?.item?.dataSet?.allowedFileType).includes(type[type.length-1])
          || (props?.item?.dataSet?.allowedFileType).includes(checkDocx[checkDocx.length - 1])
          ){
            props?.setLoader(true)
            // var image = files
            // var blob = image.slice(0, image.size);
            // var newFile = new File([blob], fileName, { type: `${image.type}` });

            // formData.append('files', newFile)
            // console.log("renderFileSize: ", renderFileSize(newFile.size));
            
        
            
             try {

            const uploadingToast = toast.loading(`Uploading File....`, {position: 'bottom-right'})
            const config = {
                onUploadProgress: function(progressEvent) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    console.log(percentCompleted)
                    toast.update(uploadingToast, {render: `${percentCompleted}% Uploaded`, type: "progress", isLoading: true})
                if (percentCompleted === 100) {
                    props?.setLoader(false)
                    toast.update(uploadingToast, {render: "File Uploaded", type: "success", isLoading: false, autoClose: 1000})
                }
                }, 
                method: 'POST',
                headers: {
                    authorization: 'ae5931560f8190ad8506dee91e8bc50799440b774defa1b44ff639e999c5335ff1beb906014862bbc2bfec530baf01917a83efac33384daa6d0a6a2646e5a65e7b2b3e4e3da9b582dc56731a710f1bd8712396b2fb22dbde428ed2a588e6bd927f55b6ff8e92a9f9cb1f0762cc1a7eecce227bf72f8749e861dac470320d9170',
                },
                body: formData
            }
            
            // let data = new FormData()
            // data.append('file', files[0])
            
            axios.post(`https://weact-api.cenergy.io/api_v1/s3/upload?email=${props?.email}`, formData, config)
            .then(res => {
                if (res.status == 200) {
                    let response = JSON.stringify(res.data);
                    response = JSON.parse(response);
                    console.log("response: ", response);
                    if (!response.keys[0] && response.keys[1]) {
                        response.keys[0] = response.keys[1]
                    }
                    props.item.url = response.keys[0];
                    props.item.value = response.keys[0];
                    props?.validateData();
                    setBtnDisable(false)
                    
                } else {
                    props?.setLoader(false)
                    props?.validateData();
                    toast.update(uploadingToast, {render: "Something went wrong", type: "error", isLoading: false, autoClose: 2000 })
                }
            })
         
                //  uploadResume(formData, props?.email).then(res => {

                //     let response = JSON.stringify(res.data);
                //     console.log(JSON.stringify(res))
                    // console.log(percentCompleted)
                //     
                    //  if(res != null){
                    //         // console.log("response: ", response);
                    //         // console.log("response: ", res);
                            
                    //     //  setResume(res.keys[0])
                        
                    //     props?.setLoader(false)
                //         toast.success('Document Uploaded Successfully')
                //         res.keys[0] = res.keys[1] !== undefined ? res.keys[1] : res.keys[0]
                //          props.item.value = res.keys[0]
                //          props.item.url = res.keys[0];
                //          props?.validateData();
                //          setBtnDisable(false)
                //          // setFormData({...formData, ['resume']: res.keys[0]})
                //      }else{
                //          setBtnDisable(false)
                //          props?.validateData();
                //          toast.success(res.message)
                //          props?.setLoader(false)
                //      }
                //  })
             }
             catch(error){
                //  console.log("error: ", error);
                props?.setLoader(false)
                toast.error('Something went wrong')
                props?.validateData();
                setBtnDisable(false)
             }
     
             return files
         } else {
            props?.validateData();
             toast.error(`only ${getAllowedFileTypeString} are allowed`, {
                 position: "bottom-right",
                 autoClose: 3000,
                 hideProgressBar: false,
                 closeOnClick: true,
             })
             setBtnDisable(false) 
             return null
         }
         
     }
 

    return (
        <div className={`single-group-child ${props?.item?.dataSet['isError'] ? 'error-in-question' : ''}`}>
            <div className="input-group1">
                <strong className="input-label overflow strongclass"><Translate>{props?.item?.dataSet?.question}</Translate>
                    {/* {(props?.item?.note).length > 0 ? `(NOTE: ${props?.item?.note})` : null} */}
                    {props?.item?.dataSet?.option?
                    <div>
                        <input type="radio" className='form-radio' disabled={props?.btnDisabled} value={props.item.dataSet.option} checked={props?.item?.value === props.item.dataSet.option} onChange={(event) => handleChange(event)}/>
                        <span style={{paddingLeft:'5px'}}><Translate>{props.item.dataSet.option}</Translate></span>
                    </div>
                    :null}
                    <input type="file" disabled={props.completeQuestionData['file'] || props?.btnDisabled} className="form-control w-100 my-2" name="resume" id="resume" onChange={(event) => console.log("event: ", handleUploadResume(event.target.files[0]))}/>
                     (<Translate>allowed file type</Translate> {`${getAllowedFileTypeString}`}){props?.item?.dataSet?.isRequired ? <span className='required'>*</span> : null}</strong>
                
                {props?.file && props?.file !== "I don't have one" ? 
                <div>
                    <Badge color="warning p-2" className="float-right" style={{color: 'black'}}><Translate>Submitted File:</Translate> {props?.file}</Badge>
                </div> : null
                }
            </div>
            <ToastContainer/>
            {props?.item?.dataSet['isError'] ? <p className='error-message-container mt-2'><Translate>{props?.item?.dataSet['errorMessage']}</Translate></p> : null}
   
        </div>
    )
}

export default Upload