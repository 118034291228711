import React from 'react'
import '../Inputs.css'
const Dropdown = (props) => {
    // console.log(props.completeQuestionData['value'].length)
    const handleChange = (event) => {
        props.completeQuestionData['value'] = event.target.value
        props?.validateData()
    }

  return (
        <div className={`single-group-child ${props?.className} ${props?.item?.dataSet['isError'] ? 'error-in-question' : ''}`}>
            <strong className='strongclass'>{props?.item?.dataSet.question}{props?.item?.dataSet.isRequired ? <span className='required'>*</span> : null}</strong>
            <select className="form-control" disabled={props?.btnDisabled} name={props?.item?.groupName} id="state" placeholder={props?.item?.placeholder} value={props?.item?.value} onChange={(event) => handleChange(event)}>
                <option value='' selected disabled hidden>Please Select Course</option>
                {
                    Object.keys(props.coursesList).map((val, i) => {
                        return(
                            <option key={props.coursesList[val].shortname} value={props.coursesList[val].fullname}>{props.coursesList[val].fullname}</option>
                        )  
                    })
                }
            </select>
            {props?.item?.dataSet['isError'] ? <p className='error-message-container'>{props?.item?.dataSet['errorMessage']}</p> : null}
        </div>
  )
}

export default Dropdown